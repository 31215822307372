import React from 'react';  import Settings from '../settings';
import axios from 'axios';

import MaterialTable from 'material-table'
import MTableToolbar from 'material-table/dist/components/m-table-toolbar'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));




export default function Branchs(props) {
    const classes = useStyles();
    const [data, setData] = React.useState({ data: [] })
    const [columns, setColumns] = React.useState({
        data: [
            { title: 'id', field: 'idcontrolRendimiento', editable: 'never' },
            { title: 'Nombre', field: 'empleadosNombre', editable: 'never' },
            { title: 'Apellidos', field: 'empleadosApellidos', filtering: false,editable: 'never' }, 
            { title: 'Área', field: 'areaNombre', editable: 'never' },
            { title: 'Producto', field: 'productosNombre', editable: 'never' },
            { title: 'Tiempo Estandar', field: 'tiempoEstandar', editable: 'never' },
            { title: 'Descuentos', field: 'controlRendimientoTotalDescuentos' },
            { title: 'Duración', field: 'controlrendimientoTiempoDuracion' },
            { title: 'Tiempo Real', field: 'TiemposReal', editable: 'never' },
            { title: 'Sub proceso', field: 'subProcesoNombre', editable: 'never' },
            { title: 'No. Pedido', field: 'controlrendimientoNumeroPedido', filtering: false,  editable: 'never'},
            { title: 'Sucursal', field: 'controlrendimientoSucursal', editable: 'never' },
            { title: 'Fecha Inicio', field: 'controlRendimientoFechaInicio', type: 'date', editable: 'never' },
            { title: 'Estado', field: 'EstadoNombre', editable: 'never' },
            
                       
        ]
    })

    const [selectedDate, setSelectedDate] = React.useState();
    const [selectedDatetwo, setSelectedDatetwo] = React.useState();

    const [btnDisabled, setBtnDisabled] = React.useState(false)

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();



    const emptyData = () =>{
        setData({data:[]})
      }
    const filter = async () => {
        var date1 = document.querySelector('#dateTextFieldInit')
        var date2 = document.querySelector('#dateTextFieldFin')
        setSelectedDate(date1.value);
        setSelectedDatetwo(date2.value);
        if(date1.value && date2.value){
          const data = await axios(`${Settings.hostName}/performance/change/${props.user.areaNombre}/${date1.value}/${date2.value}`);
          setData(data);
        }else{
          alert('los campos de Fecha de inicio y Final son requeridos')
    
        }
        
      }


   

    /// add data
   
    // edit data

    function edit(data) {
        var id = data.idcontrolRendimiento;
        axios({
            method: 'put',
            url: `${Settings.hostName}/performance/change/${id}`,
            data: {
                "total": data.controlRendimientoTotalDescuentos, 
                "duraciontarea": data.controlrendimientoTiempoDuracion,  
                                            
            }
        })
            .then(
                (res) => {
                    filter()
                    
                }, (err) => {
                    console.log(err)
                }

            )

    }


    

    return (
        <MaterialTable
            title=" "
            columns={columns.data}
            data={data.data}
            options={{               
                search: true,               
                pageSize: 50               
              }}
            localization={{
                body: {
                  emptyDataSourceMessage: 'No hay Datos',
                  editRow:{
                    deleteText:'¿Estás seguro de eliminar esté elemento?'
                  },
                },
                toolbar: {
                  searchTooltip: 'Busqueda',
                  searchPlaceholder: 'Buscar...'
                },
                header:{
                    actions: 'Editar/Eliminar'
                },
                pagination: {
                  labelRowsSelect: 'Filas',
                  labelDisplayedRows: ' {from}-{to} de {count}',
                  firstTooltip: 'Primero',
                  previousTooltip: 'Anterior',
                  nextTooltip: 'Siguiente',
                  lastTooltip: 'Último'
                }
              }}
            editable={{                
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            {
                                edit(newData)
                            }
                            resolve()
                        }, 500)
                    }),
               
            }}
            components={{
                Toolbar: props => (
                  <div>
                    <MTableToolbar {...props} />
                    <Grid container justify="space-around">
                    <TextField
                    id="dateTextFieldInit"
                    label="Fecha inicio"
                    type="date"
                    defaultValue={selectedDate}
                    pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="dateTextFieldFin"
                    label="Fecha fin"
                    type="date"
                    defaultValue={selectedDatetwo}
                    pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Button variant="outlined" disabled={btnDisabled} className={classes.button} onClick={() => filter()}>
                    Filtrar
                  </Button>

                  <Button variant="outlined" disabled={btnDisabled} className={classes.button} onClick={() => emptyData()}>
                    Quitar Filtro
                  </Button>
      
                  
                  </Grid>
                  </div>
                ),
              }}
        />
    )
}