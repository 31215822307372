import 'date-fns';
import esLocale from "date-fns/locale/es/";
import React, { Component } from 'react';import Settings from '../settings';
import axios from 'axios'
import MaterialTable from 'material-table'
import MTableToolbar from 'material-table/dist/components/m-table-toolbar'
import Moment from 'react-moment'
import 'moment-timezone';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
////table

import Paper from '@material-ui/core/Paper';

// dialogo
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

///filter
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';


const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: '100%',
  },
  appBar: {
    position: 'relative',
    backgroundColor: '#4C0066',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



function SimpleDialog(props) {
  const classes = useStyles();

  const { onClose, open } = props;

  function handleClose() {
    onClose();
  }

  function handleListItemClick(value) {
    onClose(value);
  }

  if (props.group) {

    return (

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>

          </Toolbar>
        </AppBar>
        <Paper className={classes.root}>
          <MaterialTable
            title="Total Costos Agrupados"
            data={props.data}
            columns={[
              { title: 'Grupo 1', field: 'path', },
              { title: 'Grupo 2', field: 'child', },
              { title: 'Total Costo Estandar', field: 'costoEstandar' },
              { title: 'Total Costo Real', field: 'costoReal', removable: false },
              { title: 'Total Diferencia de Costo', field: 'diferenciaCosto' },
              { title: 'Total Diferencia de Porcentaje', field: 'diferenciaProcentaje' }

            ]}
            parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
            options={{
              exportButton: true,
              search: false,
              pageSize: 50,
              pageSizeOptions: [10, 50, 100, 200, 500, 1000, 2000],
              exportAllData: true
            }}
          />
        </Paper>
      </Dialog>
    )
  }

  if (props.data.length === undefined) {
    return null
  }
  else {

    return (
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>

          </Toolbar>
        </AppBar>
        <Paper className={classes.root}>
          <MaterialTable
            title="Total Costos"
            data={props.data}
            columns={[
              { title: 'Grupo', field: 'path' },
              { title: 'Total Costo Estandar', field: 'costoEstandar' },
              { title: 'Total Costo Real', field: 'costoReal', removable: false },
              { title: 'Total Diferencia de Costo', field: 'diferenciaCosto' },
              { title: 'Total Diferencia de Porcentaje', field: 'diferenciaProcentaje' }
            ]}
            options={{
              exportButton: true,
              search: false,
              pageSize: 50,
              pageSizeOptions: [10, 50, 100, 200, 500, 1000, 2000],
              exportAllData: true
            }}
          />
        </Paper>
      </Dialog>
    );
  }


}
SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};


export default function App() {
  const classes = useStyles();
  const [totalesdata, setTotalesdata] = React.useState({})
  const [values, setValues] = React.useState({ data: [] })
  const [selectedrow, setSelectedrow] = React.useState(null)
  const [groups, setGroups] = React.useState(false)

  const [open, setOpen] = React.useState(false);

  const [areas, setAreas] = React.useState({ data: [] })
  const [area, setArea] = React.useState()

  const [selectedDate, setSelectedDate] = React.useState();
  const [selectedDatetwo, setSelectedDatetwo] = React.useState();

  const [btnDisabled, setBtnDisabled] = React.useState(false)

  const filter = async () => {

    var date1 = document.querySelector('#dateTextFieldInit')
    var date2 = document.querySelector('#dateTextFieldFin')
    setSelectedDate(date1.value);
    setSelectedDatetwo(date2.value);

    if (date1.value && date2.value && area) {
      const data = await axios(`${Settings.hostName}/queryReportfilterCosts/${date1.value}/${date2.value}/${area}`);
      setValues(data);
    } else {
      alert('los campos de área, fecha de inicio y final son requeridos')

    }


  }


  const handleChange = event => {
    setArea(event.target.value)
  }
  const handleClose = value => {
    setOpen(false);
    setGroups(false);
  };
  const emptyData = () => {
    setValues([])
  }
  const Areas = async () => {
    /// componentDidMount
    const data = await axios(`${Settings.hostName}/areas`);
    setAreas(data);
  }
  const Reports = async () => {
    /// componentDidMount
    const data = await axios(`${Settings.hostName}/queryReportCosts`);
    setValues(data);
  }
  React.useEffect(() => {
    Areas()
  }, []);

  const Format = (props) => {
    return (
      `${parseFloat(props.value).toFixed(1)}%`

    )
  }


  function formato(valor) {
    return (
      `${parseFloat(valor).toFixed(1)}%`

    )
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 1
  })


  function sumaTotales(values) {

    var large = values.length
    var dataTotales = [];
    if (large > 0) {
      setOpen(true);
      var totalCostoReal = 0; var totalDifCosto = 0; var totalCostoEstandar = 0; var totalDifPorcentaje = 0;
      var totalCostoRealSum = 0; var totalDifCostoSum = 0; var totalCostoEstandarSum = 0; var totalDifPorcentajeSum = 0;
      if (values[0].path != undefined) {
        if (values[0].groups.length > 0) {
          for (var i = 0; large > i; i++) {
            var dataPadre = values[i].groups
            var path = values[i].value
            //dataTotales.push({ 'id': i, 'path': path })
            for (var j = 0; dataPadre.length > j; j++) {
              var dataChild = dataPadre[j].data;
              var pathChild = dataPadre[j].value
              for (var k = 0; dataChild.length > k; k++) {
                totalCostoReal = totalCostoReal + dataChild[k].CostoReal;
                totalDifCosto = totalDifCosto + dataChild[k].DiferenciaCostos;
                totalCostoEstandar = totalCostoEstandar + dataChild[k].CostoEstandar;
                totalDifPorcentaje = (((totalCostoReal / totalCostoEstandar) - 1) * 100);
              }
              totalCostoRealSum = totalCostoRealSum + totalCostoReal;
              totalDifCostoSum = totalDifCostoSum + totalDifCosto;
              totalCostoEstandarSum = totalCostoEstandarSum + totalCostoEstandar;
              totalDifPorcentajeSum = (((totalCostoRealSum / totalCostoEstandarSum) - 1) * 100);


              dataTotales.push({
                'id': Math.floor(Math.random() * (999 - 0)) + 0,
                'parentId': i,
                'path': path,
                'child': pathChild,
                'costoEstandar': formatter.format(totalCostoEstandar),
                'costoReal': formatter.format(totalCostoReal),
                'diferenciaCosto': formatter.format(totalDifCosto),
                'diferenciaProcentaje': formato(totalDifPorcentaje)
              });

              totalCostoReal = 0;
              totalDifCosto = 0;
              totalCostoEstandar = 0;
              totalDifPorcentaje = 0;

            }

            dataTotales.push({
              'id': i, 'path': path,
              'costoEstandar': formatter.format(totalCostoEstandarSum),
              'costoReal': formatter.format(totalCostoRealSum),
              'diferenciaCosto': formatter.format(totalDifCostoSum),
              'diferenciaProcentaje': formato(totalDifPorcentajeSum)
            })

            totalCostoRealSum = 0; totalDifCostoSum = 0; totalCostoEstandarSum = 0; totalDifPorcentajeSum = 0;

          }
          setTotalesdata(dataTotales)
          setGroups(!groups)

        } else {
          for (var i = 0; large > i; i++) {
            var data = values[i].data
            var path = values[i].path[0]
            for (var j = 0; data.length > j; j++) {

              totalCostoReal = totalCostoReal + data[j].CostoReal;
              totalDifCosto = totalDifCosto + data[j].DiferenciaCostos;
              totalCostoEstandar = totalCostoEstandar + data[j].CostoEstandar;
              totalDifPorcentaje = (((totalCostoReal / totalCostoEstandar) - 1) * 100);

            }
            dataTotales.push({
              'path': path,
              'costoEstandar': formatter.format(totalCostoEstandar),
              'costoReal': formatter.format(totalCostoReal),
              'diferenciaCosto': formatter.format(totalDifCosto),
              'diferenciaProcentaje': formato(totalDifPorcentaje)
            });

            totalCostoReal = 0;
            totalDifCosto = 0;
            totalCostoEstandar = 0;
            totalDifPorcentaje = 0;

          }
          setTotalesdata(dataTotales)
        }

      }
      else {
        for (var i = 0; large > i; i++) {
          totalCostoReal = totalCostoReal + values[i].CostoReal;
          totalDifCosto = totalDifCosto + values[i].DiferenciaCostos;
          totalCostoEstandar = totalCostoEstandar + values[i].CostoEstandar;
          totalDifPorcentaje = (((totalCostoReal / totalCostoEstandar) - 1) * 100);
        }
        dataTotales.push({
          'path': 'Total',
          'costoEstandar': formatter.format(totalCostoEstandar),
          'costoReal': formatter.format(totalCostoReal),
          'diferenciaCosto': formatter.format(totalDifCosto),
          'diferenciaProcentaje': formato(totalDifPorcentaje)
        }
        )
        setTotalesdata(dataTotales)

      }
      setOpen(true);
    }

  }

  return (
    <div>
      <MaterialTable
        columns={[
          { title: 'No. Tarea', field: 'codigoTarea', type: 'numeric', filtering: false },
          { title: 'Cedúla', field: 'empleadosCedula', type: 'numeric', filtering: false },
          { title: 'Nombre', field: 'empleadosNombre', filtering: false },
          { title: 'Apellidos', field: 'empleadosApellidos', filtering: false },
          { title: 'Área', field: 'areaNombre', },
          { title: 'Producto', field: 'productosNombre', filtering: false },
          { title: 'Subproceso', field: 'subProcesoNombre', filtering: false },
          { title: 'Cantidad', field: 'controlrendimientoCantidad', filtering: false },
          {
            title: 'Costo MOD Estándar', field: 'CostoEstandar', type: 'currency',
            filtering: false
          },
          {
            title: 'Costo MOD Real', field: 'CostoReal', type: 'currency',
            filtering: false
          },
          {
            title: 'Diferencia Costo', field: 'DiferenciaCostos', type: 'currency',
            filtering: false
          },
          {
            title: 'Diferencia %', field: 'DiferenciaPorcentaje', type: 'numeric',
            render: rowData => (
              <Format value={rowData.DiferenciaPorcentaje} />
            ), filtering: false
          },
          { title: 'No. Pedido', field: 'controlrendimientoNumeroPedido', filtering: false },
          { title: 'Sucursal', field: 'controlrendimientoSucursal', },
          { title: 'Estado', field: 'EstadoNombre' },
          {
            title: 'Fecha Inicio',
            field: 'controlRendimientoFechaInicio',
            type: 'date', filtering: false
          },
          {
            title: 'Fecha Final', field: 'controlRendimientoFechaFinal',
            type: 'date', filtering: false
          },
          {
            title: 'Fecha Pausa', field: 'controlRendimientoFechaPausa',
            type: 'date', filtering: false
          },
          {
            title: 'Fecha Reinicio', field: 'controlRendimientoFechaReinicio',
            type: 'date', filtering: false
          },
        ]}
        data={values.data}
        title="Reporte Consulta Costos"
        options={{
          grouping: true,
          search: true,
          exportButton: true,
          exportAllData: true,
          pageSize: 50,
          pageSizeOptions: [10, 50, 100, 200, 500, 1000, 2000],
          rowStyle: rowData => ({
            backgroundColor: (selectedrow && selectedrow.selectedrow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
          }),
          filtering: true
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'No hay Datos'
          },
          toolbar: {
            searchTooltip: 'Busqueda',
            searchPlaceholder: 'Buscar...'
          },
          grouping: {
            placeholder: 'Arrastra aquí la columna para agrupar'
          },
          pagination: {
            labelRowsSelect: 'Filas',
            labelDisplayedRows: ' {from}-{to} de {count}',
            firstTooltip: 'Primero',
            previousTooltip: 'Anterior',
            nextTooltip: 'Siguiente',
            lastTooltip: 'Último'
          }
        }}
        onRowClick={((evt, selectedrow) => setSelectedrow({ selectedrow }))}
        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar {...props} />

              <SimpleDialog data={totalesdata} group={groups} open={open} onClose={handleClose} />


              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <Grid container justify="space-around">
                  <Button variant="outlined" className={classes.button} onClick={() => sumaTotales(props.data)}>
                    Totales
      </Button>
                  <TextField
                    required="true"
                    id="area"
                    select
                    label="Áreas"
                    className={classes.textField}
                    value={area}
                    onChange={handleChange}
                    style={{ margin: 8, width: '30%' }}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}

                    margin="normal"
                    variant="outlined"
                  >
                    {areas.data.map((option, i) => (
                      <MenuItem key={i} value={option.areaNombre}>
                        {option.areaNombre}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    id="dateTextFieldInit"
                    label="Fecha inicio"
                    type="date"
                    defaultValue={selectedDate}
                    pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="dateTextFieldFin"
                    label="Fecha fin"
                    type="date"
                    defaultValue={selectedDatetwo}
                    pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Button variant="outlined" disabled={btnDisabled} className={classes.button} onClick={() => filter()}>
                    Filtrar
                  </Button>

                  <Button variant="outlined" disabled={btnDisabled} className={classes.button} onClick={() => emptyData()}>
                    Quitar Filtro
                  </Button>
                </Grid>
              </MuiPickersUtilsProvider>

            </div>
          ),
        }}
      />
    </div>
  )

}

