import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
      whiteSpace: 'normal'
    },
  }));
export default function ListSubItems(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    function handleClick() {
        setOpen(!open);
    }
    if(props){

        return (
            <div>
                <ListItem button onClick={handleClick}>
                    <ListItemIcon>
                        {props.icon}
                    </ListItemIcon>
                    <ListItemText primary={props.text} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            props.subItems.map((element, i) => (
                                <Link key={i} to={element.path}>
                                <ListItem button key={i} className={classes.nested}>
                                    <ListItemIcon>
                                       { element.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={element.nombre} />
                                </ListItem>
                                </Link>
                            ))
    
                        }
    
                    </List>
                </Collapse>
            </div>
        )

    }else{return false}
   



}