import React, {useContext} from 'react'
import Settings from "../../settings";
import axios from "axios";
import { AppContext } from "../../../contextApi/Context";
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
function Delete({ idcontrolRendimiento, creador, idUser}) {

    const {    
        userData, socket
      } = useContext(AppContext);

      const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

    function _delete(id) {
        axios({
          method: "delete",
          url: `${Settings.hostName}/performance/${id}`,
        }).then(
          (result) => {
            //All();
            localStorage.removeItem(userData.Idempleados);
            socket.emit('mensaje',{
              name:userData.empleadosNombre,
              mensaje: 'Elimina la tarea',
              data: idUser,
              room: userData.cedulaEncargado === 0?  creador : userData.cedulaEncargado})
              handleClose();
            
          },
          (error) => {
            console.log("error");
          }
        );
      }

  return (
    <Grid item xs={3}>
            <IconButton
              aria-label="cancelar"
              title="Eliminar"
              disabled={userData.rolesNombre !== 'Operario' ? false: true}
              onClick={handleClickOpen}
            >
              <DeleteIcon />
            </IconButton>

            <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Desea Eliminar?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Desea eliminar la Tarea con el No. {idcontrolRendimiento}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancelar
          </Button>
          <Button onClick={()=> _delete(idcontrolRendimiento) } color="secundary" >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
          </Grid>
  )
}

export default Delete