import React from 'react';
import Moment from 'react-moment';
import { BrowserRouter as Router, Route,  Redirect } from "react-router-dom";
import Dashboard  from './components/Dashboard'
import Login from './components/Login';

//import Test from './components/Reports/ReportProductFinal'

// Start the pooled timer which runs every 60 seconds
// (60000 milliseconds) by default.
Moment.startPooledTimer(1000);

// Or set the update interval. This will update the mounted
// instances every 30 seconds.
// Moment.startPooledTimer(30000);

export default class App extends React.Component {
state = {
  login: true,
  user:[]
}
_login =() =>{
  return (
  <Login  login={this.isLogin}/>
 // <Test />
  )
}

  _Dashboard =() =>{
    return (
    <Dashboard user={this.state.user} logout={this.logout}/>
    
    )
  }
  

  

  isLogin = (user) =>{
    this.setState({
      login : !this.state.login,
      user : user
    })
    
  }

  logout =()=>{
    this.setState({
      login : !this.state.login,
      user : []   
    })
  }

  render(){

    {if(this.state.login){
      return ( <Router>
      <div className={"site-content"}>      
      <Route  path="/login" component={this._login} />
      <Redirect to={{ pathname: '/login' }} />
      </div>
      </Router> )
    }else{
      return (
        <Router>
          <div className={"site-content"}>      
            <Route path="/dashboard" component={this._Dashboard} />           
            <Redirect to={{ pathname: '/dashboard' }} />            
          </div>
        </Router>
        );
    }

   }

 

}  
  
}


