import React, { useState } from 'react';
import More from '@material-ui/icons/Add';
import Less from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles(theme => ({
    margin: {
      marginLeft: '10px',
      display: 'inline-flex',
    }    
  }));

export default function ItemDisscount(props) {
    const classes = useStyles();
    const [count, setCount] = useState(0);    

    const more = () => {
        setCount(count + 1)
        props.click(props.data, count + 1,(count + 1)*props.total)
    }

    const less = () => {
        if(count > 0){
            setCount(count - 1)
            props.click(props.data, count - 1,(count - 1)*props.total)
        }
        
    }
    return (
        <Grid item xs={12} lg={12} sm={12}>                           
            <Fab size="medium" color="secondary" aria-label="less" className={classes.margin}>
                <Less fontSize="default" onClick={() => less()} />
            </Fab>
            <p className={classes.margin} > {count}</p>
            <Fab size="medium" color="primary" aria-label="add" className={classes.margin}>
                <More onClick={() => more()} />
            </Fab>
        </Grid>
    );
}