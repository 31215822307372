import 'date-fns';
import esLocale from "date-fns/locale/es/";
import React, { Component } from 'react';import Settings from '../settings';
import axios from 'axios'
import MaterialTable from 'material-table'
import MTableToolbar from 'material-table/dist/components/m-table-toolbar'
import Moment from 'react-moment'
import 'moment-timezone';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
////table

import Paper from '@material-ui/core/Paper';

// dialogo
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

///filter
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';


const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: '100%',
  },
  appBar: {
    position: 'relative',
    backgroundColor: '#4C0066',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));



export default function App() {
  const classes = useStyles();
  
  const [values, setValues] = React.useState({
    areaNombre: '',
  });

  const [valuesSP, setValuesSP] = React.useState({
    subProcesoNombre: ''
  });
  const [valuesPRD, setValuesPRD] = React.useState({
    productosNombre: ''
  });

  const [selectedrow, setSelectedrow] = React.useState(null)  
  const [selectedDate, setSelectedDate] = React.useState();
  const [selectedDatetwo, setSelectedDatetwo] = React.useState();
  const [areas, setAreas] = React.useState({ data: [] })
  const [subprocesos, setSubprocesos] = React.useState({ data: [] })
  const [productos, setProductos] = React.useState({ data: [] })
  const [alltask, setAlltask] = React.useState([])
  const handleChange = name => async event =>{
    var dataTotales = [];
    var date1 = document.querySelector('#dateTextFieldInit')
    var date2 = document.querySelector('#dateTextFieldFin')
    setSelectedDate(date1.value);
    setSelectedDatetwo(date2.value);

    if (date1.value === '' || date2.value === '') {
        alert('las fechas no deben estara vacias');
       
      } else{

        switch (name) {
            case 'areaNombre':
                setValues({ ...values, [name]: event.target.value });
                const Subprocesos = await axios.get(`${Settings.hostName}/queryallThread/${event.target.value}`);
                setSubprocesos(Subprocesos)
                break;
            case 'subProcesoNombre':
                setValuesSP({ ...valuesSP, [name]: event.target.value });
                const Products = await axios.get(`${Settings.hostName}/queryallproducts/${date1.value}/${date2.value}/${values.areaNombre}/${event.target.value}`);
                setProductos(Products)
                break;
            case 'productosNombre':
                setValuesPRD({ ...valuesPRD, [name]: event.target.value });
        var prdt = encodeURI(event.target.value)
        const data = await axios(
          `${Settings.hostName}/querytimesfortasks/${selectedDate}/${selectedDatetwo}/${values.areaNombre}/${valuesSP.subProcesoNombre}/${prdt}`
        );

        var groupBy = function (xs, key) {
          return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
          }, {});
        };
        var groubedByTeam = await groupBy(data.data[0], "empleadosCedula");
    
        var AllObject = Object.values(groubedByTeam)
        var productName, nameUser, allTasks = '';
        var averageReal = 0; var averageStandar = 0; var differenceNum = 0; var differencePercentage = 0;
        AllObject.forEach(element => {
    
          element.forEach(elmnt => {
            productName = elmnt.productosNombre
            nameUser = elmnt.empleadosNombre + ' ' + elmnt.empleadosApellidos
            allTasks += parseFloat(elmnt.controlrendimientoTiempoReal / elmnt.controlrendimientoCantidad).toFixed(1) + ' | '
            averageReal = averageReal + (elmnt.controlrendimientoTiempoReal / elmnt.controlrendimientoCantidad);
            averageStandar = averageStandar + elmnt.TiempoEstandar
          });
          differenceNum = parseFloat((averageReal / element.length) - (averageStandar / element.length)).toFixed(1);
    
          differencePercentage = parseFloat(((averageReal / element.length) / (averageStandar / element.length) - 1) * 100).toFixed(1)
    
          dataTotales.push({
            'producto': productName,
            'usuario': nameUser,
            'tiemposTareas': allTasks,
            'promedioReal': parseFloat(averageReal / element.length).toFixed(1),
            'promedioEstandar': parseFloat(averageStandar / element.length).toFixed(1),
            'diferencia': differenceNum,
            'diferenciaPorcentual': differencePercentage
          })
          productName = ''; nameUser = ''; allTasks = ''; averageReal = 0; averageStandar = 0; differenceNum = 0; differencePercentage = 0
        });
        setAlltask(dataTotales)

                break;
            default:
                break;
        }
      }


  }
  const Areas = async () => {
    const areas = await axios.get(`${Settings.hostName}/area`);
    setAreas(areas)
  }
  
  React.useEffect(() => {
    Areas()
  }, []);

 


 
  return (
    <div>
       <MaterialTable
        columns={[
          { title: 'NOMBRE', field: 'usuario', filtering: false },
          {title: 'PRODUCTO', field: 'producto', filtering: false },
          {title: 'TIEMPO DE LAS TAREAS', field: 'tiemposTareas', filtering: false },
          {title: 'PROMEDIO REAL', field: 'promedioReal', filtering: false },
          {title: 'TIEMPO ESTANDAR', field: 'promedioEstandar', filtering: false },
          {title: 'DIFERENCIA', field: 'diferencia', filtering: false },
          {title: 'DIFERENCIA PORCENTUAL', field: 'diferenciaPorcentual', filtering: false },
        ]}
        data={alltask}
        //parentChildData={(row, rows) => rows.find(a => a.controlrendimientoID === row.controlrendimientoID)}
        title="Reporte tiempo de tareas por producto"
        options={{
          grouping: true,
          search: true,
          exportButton: true,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100, 200, 500, 1000, 2000],
          rowStyle: rowData => ({
            backgroundColor: (selectedrow && selectedrow.selectedrow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
          }),
          filtering: true
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'No hay Datos'
          },
          toolbar: {
            searchTooltip: 'Busqueda',
            searchPlaceholder: 'Buscar...'
          },
          grouping: {
            placeholder: 'Arrastra aquí la columna para agrupar'
          },
          pagination: {
            labelRowsSelect: 'Filas',
            labelDisplayedRows: ' {from}-{to} de {count}',
            firstTooltip: 'Primero',
            previousTooltip: 'Anterior',
            nextTooltip: 'Siguiente',
            lastTooltip: 'Último'
          }
        }}
        onRowClick={((evt, selectedrow) => setSelectedrow({ selectedrow }))}
        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar {...props} />
              <Grid container justify="space-around">
              <TextField
                id="dateTextFieldInit"
                label="Fecha inicio"
                type="date"
                defaultValue={selectedDate}
                pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                id="dateTextFieldFin"
                label="Fecha fin"
                type="date"
                defaultValue={selectedDatetwo}
                pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                required="true"
                id="area"
                select
                label="Áreas"
                className={classes.textField}
                value={values.areaNombre}
                onChange={handleChange('areaNombre')}
                style={{ margin: 8, width: '20%' }}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}

                margin="normal"
                variant="outlined"
              >
                {areas.data.map(option => (
                  <MenuItem key={option.idarea} value={option.areaNombre}>
                    {option.areaNombre}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required="true"
                id="SubProcesos"
                select
                label="SubProcesos"
                className={classes.textField}
                value={valuesSP.subProcesoNombre}
                onChange={handleChange('subProcesoNombre')}
                style={{ margin: 8, width: '20%' }}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}

                margin="normal"
                variant="outlined"
              >
                {subprocesos.data.map((option, i) => (
                  <MenuItem key={i} value={option.subProcesoNombre}>
                    {option.subProcesoNombre}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required="true"
                id="productosNombre"
                select
                label="productos"
                className={classes.textField}
                value={valuesPRD.productosNombre}
                onChange={handleChange('productosNombre')}
                style={{ margin: 8, width: '20%' }}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}

                margin="normal"
                variant="outlined"
              >
                {productos.data.map((option, i) => (
                  <MenuItem key={i} value={option.productosNombre}>
                    {option.productosNombre}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            </div>
          ),
        }}
      />
    </div>
  )

}

