import React from 'react';
import axios from 'axios';import Settings from '../../settings';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';


import AutoComplete from "../../Control/AutoComplete";


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 320,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 650,
    },
  },
};

function getStyles(name, productName, theme) {
    return {
      fontWeight:
      productName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }


export default function DialogSelect(props) {
  //console.log(props)
  const classes = useStyles();
  const theme = useTheme();
  const [state, setState] = React.useState({
    open: false,
    prods: '',
  });

  const [dataPro, setDataPro] = React.useState([])

  const [productName, setProductName] = React.useState([]);

  const handleChange = event => {
    setProductName(event.target.value);
  };

  async function addChild (value){
     await axios({
      method: 'post',
      url: `${Settings.hostName}/productschildrens/add`,
      data: {
      codPadre: props.cod,
      codHijo: value.idproductos,
      nombre: value.productosNombre,
      subproceso: value.subProcesoNombre,
      codigoHijoFijo: value.productosCodigo
    },headers: {'Access-Control-Allow-Origin': '*'}
      })

  }

   const handleSave = async () => {
    dataPro.map(element => (        
        addChild(element.product)        
    ));
    handleClose();
    props.refresh();
   }

  

  const handleClickOpen = () => {
    setState({ ...state, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleDelete = (chipToDelete) => () => {    
    setDataPro((chips) => chips.filter((chip) => chip.product.idproductos !== chipToDelete.idproductos));
  };

  const DataProducts = async () =>{
      const data =await axios(`${Settings.hostName}/productschildrens/`);
      setDataPro(data)
  }

  React.useEffect(() => {
    //DataProducts();
  }, []);


  function SearchProd(name) {  
   // console.log(name)
    setDataPro([...dataPro, {product: name}])   
  }

  return (
    <div style={{height: 'auto'}} >
      <AddIcon onClick={handleClickOpen}/>
      <Dialog disableBackdropClick fullWidth disableEscapeKeyDown  open={state.open} onClose={handleClose}>
        <DialogTitle>Agregar a: {props.cod}</DialogTitle>
        <DialogContent style={{height:'300px'}}>
        {dataPro.length > 0 &&
        
        dataPro.map((children)=>(
          <Chip variant="outlined" key={children.product.idproductos} color="secondary" onClick={handleDelete(children.product)} label={<div>
            <span>
            {children.product.productosNombre}
            </span><br/>
            <span>
            {children.product.subProcesoNombre}
            </span>
          </div>}/>

        ))
        }{
          dataPro.length === 0 &&
          <> <span> no hay productos asociados </span></>
        }
        <AutoComplete funcSearch={SearchProd} />
         </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}