import React, {useContext} from 'react';
import axios from 'axios';
import Settings from "../settings";
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';

import {AppContext} from '../../contextApi/Context' 
var suggestions;

var fctGetValue;

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  
  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion, query);
  const parts = parse({'name':suggestion.productosNombre, 'subp':suggestion.subProcesoNombre, 'act':suggestion.actividad} , matches);
  
  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map(part => (
          <span key={part.text.name} style={{ fontWeight: part.highlight ? 500 : 400 }} title={part.text.act}>
            {part.text.name} / {part.text.subp} / {part.text.act}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

function getSuggestions(value) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions?.filter(suggestion => {
      //console.log(suggestion)
        const keep =
          count < 100 && suggestion.productosNombre.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

function getSuggestionValue(suggestion) {
  //console.log(suggestion) //aqui ejecuto la funcion de pasar los datos a al padre
  fctGetValue(suggestion)
  return suggestion.productosNombre +' / ' + suggestion.subProcesoNombre + ' / '+ suggestion.actividad
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    flexGrow: 1,
    margin:'10px',
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 90,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  
}));

export default function IntegrationAutosuggest({funcSearch}) {

  const {userData, allProductsVita } = useContext(AppContext);

  fctGetValue = funcSearch;
  const classes = useStyles();
 
  const [state, setState] = React.useState({
    single: '',
    popper: '',
  });

  const Reset = name => (event) => {
    // console.log(newValue)
     setState({
       ...state,
       [name]: '',
     });
   };

  const AutomateChange = async () =>{
    //const Products = await axios.get(`${Settings.hostName}/productssearch`);
    let data = []
    //console.log(window.location)
    if(window.location.pathname === "/products"){
      data = allProductsVita.data;
    }else{
      //console.log(Products.data)
      const datafilter = allProductsVita.data.filter(element => element.areaNombre === userData.areaNombre)
    //  console.log(datafilter)
    data = datafilter
    }
    
    //console.log(data)
    suggestions = data
  }
 

React.useEffect(()=>{  
  AutomateChange()
},[])

  const [stateSuggestions, setSuggestions] = React.useState([]);

  const handleSuggestionsFetchRequested = ({ value }) => {
    //console.log(value)
    setSuggestions(getSuggestions(value));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = name => (event, { newValue }) => {
   // console.log(newValue)
    setState({
      ...state,
      [name]: newValue,
    });
  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  };
  //console.log(getSuggestionValue())

  


  return (
    <div className={classes.root}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          id: 'react-autosuggest-simple',
          label: 'Buscador de Productos',
          placeholder: 'VitaHome...',
          value: state.single,
          onChange: handleChange('single'),
          onDoubleClick: Reset('single')
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    
     
    </div>
  );
}