import React,{useContext} from "react";
import axios from "axios";
import { AppContext } from "../../../contextApi/Context";
import Settings from "../../settings";

import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import QRCode from "qrcode.react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { SnackbarProvider, useSnackbar } from "notistack";

///dialog

import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";




const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    padding: "7%",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginTop: "15%",
  },
  paperRight: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: "15%",
  },
  paperleft: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: "5%",
  },
  fab: {
    margin: theme.spacing(1),
  },
  appBar: {
    position: "relative",
  },
}));

function DialogAdd(props) {
  const {    
    socket
 } = useContext(AppContext);

  const classes = useStyles();
  const { onClose, open } = props;

  const Broadcast = () => {
    socket.emit("new product", { data: 'nuevo evento' })
    //GetListMyDisscounts()
  }  


  async function handleClose() {
   await socket.emit("new product", { data: 'nuevo evento' });
    onClose();    
    return;
  }

  if (props.view === "view") {
    return (
      <Dialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Materiales consumibles del producto : {props.descripcion}
            </Typography>
          </Toolbar>
        </AppBar>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        fullScreen
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Materiales : {props.descripcion}
            </Typography>
          </Toolbar>
        </AppBar>
      </Dialog>
    );
  }
}

DialogAdd.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function MyAppForm(props) {

 
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [nameview, setNameview] = React.useState();
  const [values, setValues] = React.useState({
    codigo: 0,
    nombre: "",
    idSubproceso: 0,
    tiempo: 0,
    idInventoryType: "",
    padre: false,
    padre: false,
    actividad: "",
    idArea: 0,
    tiempoSistema: 0,
    tiempo3primerosSistema: 0
  });
  const [subproc, SetSubproc] = React.useState({ data: [] });
  const [inventorytype, setInventorytype] = React.useState({ data: [] });
  const [area, setArea] = React.useState({ data: [] });
  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };
  const handleChangeCK = (name) => (event) => {
    setValues({ ...values, [name]: event.target.checked });
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    enqueueSnackbar("Producto Editado.", {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  const handleClickDialog = () => {
    setOpen(true);
    setNameview("add");
  };

  const handleClickDialogView = () => {
    setOpen(true);
    setNameview("view");
  };

  const handleCloses = (value) => {
    setOpen(false);
  };

  const handleSave = (e) => {    
    e.preventDefault();
    AddNewProduct()
   // Broadcast()
    //setOpen(true)
    //handleCloses()
  };

  //const [users, setUsers] =  React.useState({ data:[]})
  function getSubProceso() {
    function getSubProceso() {
      return axios.get(`${Settings.hostName}/subprocess`);
    }

    function getInventoryType() {
      return axios.get(`${Settings.hostName}/inventorytype`);
    }
    function getArea() {
      return axios.get(`${Settings.hostName}/area`);
    }

    axios
      .all([getSubProceso(), getInventoryType(), getArea()])
      .then(
        axios.spread(function (listSubProcess, listInventoryType, getArea) {
          // Both requests are now complete
          SetSubproc(listSubProcess);
          setInventorytype(listInventoryType);
          setArea(getArea);
        })
      )
      .catch(function (error) {
        console.log(error);
      });
  }

const AddNewProduct =async  () =>{
 
  const AddNewProd = await axios({
    method: "post",
    url: `${Settings.hostName}/products/add`,
    data: {
      codigo: values.codigo,
      nombre: values.nombre,
      idsubproceso: values.idSubproceso,
      tiempoestandar: values.tiempo,
      tipoInventarioID: values.idInventoryType,
      actividad: values.actividad,
      areaID: values.idArea,
      padre: values.padre,
    },
  });
  if (AddNewProd.status === 200) {

    const id = parseInt(props.id);

    const EditProd = await axios({
      method: "put",
      url: `${Settings.hostName}/products/${id}`,
      data: {
        codigo: values.codigo,
        nombre: values.nombre,
        idsubproceso: values.idSubproceso,
        tiempoestandar: values.tiempo,
        tipoInventarioID: values.idInventoryType,
        actividad: values.actividad,
        areaID: values.idArea,
        padre: values.padre,
        tiempoSistema: values.tiempoEstandarSistema,
        tiempo3primerosSistema: values.tiempoBajos3primeros
      },
    });
  
    if (EditProd.status === 200) {
      
      
      handleClick();
     
      
      //props.users();
    }        
    
  }
  return;
  
}


  const editUser = async () => {   /// componentDidMount
    const id = parseInt(props.id);
    if (!values.codigo) {
      const GetProduct = await axios.get(`${Settings.hostName}/productsEdit/${id}`);

      if (GetProduct.status === 200) {
        getSubProceso();
        setValues({
          codigo: GetProduct.data.productosCodigo,
          nombre: GetProduct.data.productosNombre,
          idSubproceso: GetProduct.data.subProcesoId,
          tiempo: GetProduct.data.tiempoEstandar,
          idInventoryType: GetProduct.data.tipoinventarioId,
          padre: GetProduct.data.productosPadre,
          actividad: GetProduct.data.actividad,
          idArea: GetProduct.data.areaId,
          tiempoSistema: GetProduct.data.tiempoEstandarSistema ?? 0,
          tiempo3primerosSistema: GetProduct.data.tiempoBajos3primeros ?? 0
        });
      }
    }
  };

  React.useEffect(() => {
    editUser();
  }, []);


  
  return (
    <React.Fragment>
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} sm={6}>
            <Paper className={classes.paper}>
              <form
                className={classes.container}
                noValidate
                autoComplete="off"
                onSubmit={handleSave}
              >
                <TextField
                  id="codigo"
                  label="Ítem"
                  style={{ margin: 8 }}
                  placeholder="Digita el código Ítem"
                  fullWidth
                  value={values.codigo}
                  onChange={handleChange("codigo")}
                  type="number"
                  margin="normal"
                />

                <TextField
                  id="nombre"
                  label="Descripción de ítem"
                  style={{ margin: 8 }}
                  placeholder="Digita el Nombre"
                  fullWidth
                  margin="normal"
                  value={values.nombre}
                  onChange={handleChange("nombre")}
                />

                <TextField
                 
                  id="actividad"
                  label="Descripción de actividad"
                  style={{ margin: 8 }}
                  placeholder="Digita actividad"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={3}
                  value={values.actividad}
                  onChange={handleChange("actividad")}
                />
                <TextField
                  required
                  id="idArea"
                  select
                  label="Seleccionar Área"
                  className={classes.textField}
                  value={values.idArea}
                  onChange={handleChange("idArea")}
                  fullWidth
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                >
                  {area.data?.map((option) => (
                    <MenuItem key={option.idarea} value={option.idarea}>
                      {option.areaNombre}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="idSubproceso"
                  select
                  label="Seleccionar Sub proceso"
                  className={classes.textField}
                  value={values.idSubproceso}
                  onChange={handleChange("idSubproceso")}
                  fullWidth
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                >
                  {subproc.data.map((option) => (
                    <MenuItem
                      key={option.idsubProceso}
                      value={option.idsubProceso}
                    >
                      {option.subProcesoNombre}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="idInventoryType"
                  select
                  label="Seleccionar tipo de inventario"
                  className={classes.textField}
                  value={values.idInventoryType}
                  onChange={handleChange("idInventoryType")}
                  fullWidth
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                >
                  {inventorytype.data.map((option) => (
                    <MenuItem
                      key={option.idtipoinventario}
                      value={option.idtipoinventario}
                    >
                      {option.tipoinventarioNombre}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="tiempo"
                  label="Tiempo Estandar"
                  style={{ margin: 8 }}
                  placeholder="Digita el tiempo"
                  fullWidth
                  value={values.tiempo}
                  onChange={handleChange("tiempo")}
                  type="number"
                  margin="normal"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.padre}
                      onChange={handleChangeCK("padre")}
                      value={values.padre}
                    />
                  }
                  label="¿Es Producto Padre?"
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Aceptar
                </Button>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6} sm={6}>
            <Paper className={classes.paperRight}>
              <QRCode value={values.codigo === ''? values.codigo.toString(): '00'} level={"L"} size={200} />
            </Paper>
            
            <Paper className={classes.paperleft}>
           
            <TextField
                  id="tiempoStandarSistema"
                  label="Tiempo Estándar Regresión Lineal"
                  style={{ margin: 8 }}                  
                  fullWidth
                  value={values.tiempoSistema.toFixed(2)}                 
                  type="number"
                  margin="normal"
                  disabled= "true"
                />
                <TextField
                  id="tiempoStandarSistema"
                  label="Tiempo Estándar regresión Lineal con base a los tres Mejores Tiempos"
                  style={{ margin: 8 }}                  
                  fullWidth
                  value={values.tiempo3primerosSistema.toFixed(2)}                 
                  type="number"
                  margin="normal"
                  disabled= "true"
                />
            </Paper>
          </Grid>

          <DialogAdd
            open={open}
            onClose={open}
            codigo={props.id}
            descripcion={values.nombre}
            view={nameview}
          />
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default function EditProduct(props) {
  return (
    <SnackbarProvider maxSnack={2}>
      <MyAppForm users={props.addnewsUser} id={props.id} close={props.close} />
    </SnackbarProvider>
  );
}
