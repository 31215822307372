import React, {useContext} from 'react'
import Settings from "../../settings";
import axios from "axios";
import { AppContext } from "../../../contextApi/Context";

import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import PauseIcon from "@material-ui/icons/PauseCircleFilled";


const pause = ({
    idcontrol, fechaInicio, TiempoDuracion, fechaReinicio, status, creador, idUser
    
}) => {

    const {    
        userData, socket
      } = useContext(AppContext);


    async function _paused(id, fecha, totalparcial, fechareinicios) {
        const dtimezone = new Date().getTimezoneOffset() * 60000;
        const TodaylocalIsoTime = new Date(Date.now() - dtimezone).toISOString();
        if (status !== "Pendiente") {
          if (totalparcial === 0) {
            var fechaInicio = new Date(fecha).getTime();
          } else {
            var fechaInicio = new Date(fechareinicios).getTime();
          }
          var fechaFin = (await new Date().getTime()) - dtimezone;
          var diff = fechaFin - fechaInicio;
          var TiempoDuracionTarea = parseFloat(diff / 60000).toFixed(0);
          /// devuelve los minutos que se demoro en realizar la tarea
          //var today = new Date().toISOString()
          // agregar los datos de descuentos
    
          if (TiempoDuracionTarea > 26170000) {
            alert("por favor actualiza el sistema");
            //props.refresh();
            //props.allrefresh();        
          } else {


            const PauseAPI = await axios.put(`${Settings.hostName}/performance/${id}`,{
              id: id,
              fechaFinal: "",    
              fechaPausa:
                TodaylocalIsoTime === undefined
                  ? new Date(Date.now() - dtimezone).toISOString()
                  : TodaylocalIsoTime,
              estado: 2,
              duraciontarea: Number(TiempoDuracionTarea) + Number(totalparcial),
              fechareinicio: "",
              idUSer:idUser
            })

            if(PauseAPI.status === 200){
              localStorage.removeItem(idUser);
              socket.emit('mensaje',{
                name:userData.empleadosNombre,
                mensaje: 'Pauso la tarea',
                data: idUser,
                room: userData.cedulaEncargado === 0?  creador : userData.cedulaEncargado})
                
            }

            }

          /*   axios({
              method: "put",
              url: `${Settings.hostName}/performance/${id}`,              
              data: {
                id: id,
                fechaFinal: "",    
                fechaPausa:
                  TodaylocalIsoTime === undefined
                    ? new Date(Date.now() - dtimezone).toISOString()
                    : TodaylocalIsoTime,
                estado: 2,
                duraciontarea: Number(TiempoDuracionTarea) + Number(totalparcial),
                fechareinicio: "",
                idUSer:idUser
              },
            }).then(
              async (result) => {
                
                try {
                  
                } catch (error) {
                  
                }
                socket.emit('mensaje',{
                  name:userData.empleadosNombre,
                  mensaje: 'Pauso la tarea',
                  data: idUser,
                  room: userData.cedulaEncargado === 0?  creador : userData.cedulaEncargado})
                  
              },
              (error) => {
                console.log(error);
              }
            ); 
          }*/
        } else {
          alert("Esta tarea ya esta pausada");
        }
      }



  return (
    <Grid item xs={2}>
            <IconButton
              aria-label="pause"
              color="secondary"
              title="Pausar"
              disabled={userData.rolesNombre !== 'Operario' ? false: true}
              onClick={() =>
                _paused(
                    idcontrol, fechaInicio, TiempoDuracion, fechaReinicio
                )
              }
            >
              <PauseIcon />
            </IconButton>
          </Grid>
  )
}

export default pause