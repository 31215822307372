import React , {useContext} from 'react';
import Settings from './settings';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Paper from '@material-ui/core/Paper';

import {AppContext} from '../contextApi/Context'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="#">
                dibAI systems
      </Link>{' '}
            {new Date().getFullYear()}

        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    root: {
        padding: theme.spacing(1, 2),
    },
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,        
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn(props) {
    const classes = useStyles();
    const [users, setUsers] = React.useState({ data: [] })

    const [values, setValues] = React.useState();
    
    const {setUserData, userData, setAllProductsVita, setAllUsersVita, setAllDiscountsVita, setMycurrenttask, socket} = useContext(AppContext);

    


    const handleChange = event => {
        setValues(event.target.value);
    };


    const KeyPressEnter = async (e) => {
        if (e.charCode == 13) {
            if(values){
                const data = await axios({
                    method: 'post',
                    url: `${Settings.hostName}/login/`,
                    data: {
          
                      "id": parseInt(values),
                      
          
                    },
                    headers: {'Access-Control-Allow-Origin': '*'}
                  })                  
                 
            if (data.status === 200 && data.data.length > 0) {                
              
                socket.emit("conectado", ({
                    name:data.data[0].empleadosCedula, 
                    room:data.data[0].Idempleados,
                }));    
                
                
               
                const Products = await axios(`${Settings.hostName}/products/${data.data[0].areaNombre}`);
                const Users = await  axios(`${Settings.hostName}/usersarea/${data.data[0].areaNombre}`)
                const Discounts = await axios(`${Settings.hostName}/discounts`);

                let MyTasks;
                if(data.data[0].rolesNombre === "Operario"){
                     MyTasks = await axios(`${Settings.hostName}/performance/userid/${data.data[0].Idempleados}`);
                     setMycurrenttask(MyTasks)
                }
                
                setUserData({
                    Idempleados: data.data[0].Idempleados,
                    empleadosCedula:data.data[0].empleadosCedula,
                    empleadosNombre: data.data[0].empleadosNombre, 
                    empleadosApellidos: data.data[0].empleadosApellidos,      
                    rolesNombre:data.data[0].rolesNombre,    
                    empleadosCostoMinuto:data.data[0].empleadosCostoMinuto,
                    areaNombre:data.data[0].areaNombre,
                    cedulaEncargado:data.data[0].cedulaEncargado === null ? 0 : data.data[0].cedulaEncargado
                })
                setAllUsersVita(Users)
                setAllProductsVita(Products)
                setAllDiscountsVita(Discounts)
                
                props.login(data.data);
            } else {
                alert('Password no registrado')
            }
    
            }else{
    
                alert('Password Vacio')
            }
          }         
        
    }    
    const handleLogin = async () => {
        if(values){
            const data = await axios({
                method: 'post',
                url: `${Settings.hostName}/login/`,
                data: {
      
                  "id": parseInt(values),
                  
      
                },
                headers: {'Access-Control-Allow-Origin': '*'} 
              })

       // var data_filter = users.data.filter(element => element.empleadosCedula === parseInt(values)) /// filtro el password del usuario en todos los usuarios.    
        if (data.status === 200 && data.data.length > 0) {

            socket.emit("conectado", ({
                name:data.data[0].empleadosCedula, 
                room:data.data[0].Idempleados,
            }));


            
            setInterval(() => {
                socket.emit("conectado", ({
                    name:data.data[0].empleadosCedula, 
                    room:data.data[0].Idempleados,
                })); 
            }, 900000);

            const Products = await axios(`${Settings.hostName}/products/${data.data[0].areaNombre}`);
            const Users = await  axios(`${Settings.hostName}/usersarea/${data.data[0].areaNombre}`);
            const Discounts = await axios(`${Settings.hostName}/discounts`);
            let MyTasks;
                if(data.data[0].rolesNombre === "Operario"){
                     MyTasks = await axios(`${Settings.hostName}/performance/userid/${data.data[0].Idempleados}`);
                     setMycurrenttask(MyTasks)
                }
           // console.log(data)
            setUserData({
                Idempleados: data.data[0].Idempleados,
                empleadosCedula:data.data[0].empleadosCedula,
                empleadosNombre: data.data[0].empleadosNombre, 
                empleadosApellidos: data.data[0].empleadosApellidos,      
                rolesNombre:data.data[0].rolesNombre,    
                empleadosCostoMinuto:data.data[0].empleadosCostoMinuto,
                areaNombre:data.data[0].areaNombre,
                cedulaEncargado:data.data[0].cedulaEncargado === null ? 0 : data.data[0].cedulaEncargado
            })

            setAllUsersVita(Users)
            setAllProductsVita(Products)
            setAllDiscountsVita(Discounts)
            
            props.login(data.data);
        } else {
            alert('Password no registrado')
        }

        }else{

            alert('Password Vacio')
        }
        
    }    

    ///END SCAN QR

    return (

        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Paper className={classes.root}>
                <div className={classes.paper}>
                    <img alt="Remy Sharp" src="../images/dibay.png" className={classes.bigAvatar} />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handleChange}  
                        onKeyPress={KeyPressEnter}                      
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleLogin}
                    >
                        Ingresar
          </Button>

                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Paper>
        </Container>

    );
}