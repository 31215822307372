import React from 'react'
import axios from 'axios'; import Setting from '../settings'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
////table

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';



// dialogo


const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: '90%',
  },
}));



export default function App() {
  const classes = useStyles();
  let Total = 0

  const [selectedDate, setSelectedDate] = React.useState();
  const [selectedDatetwo, setSelectedDatetwo] = React.useState();
  const [productsFather, setProductsFather] = React.useState()
  const [productsFathers, setProductsFathers] = React.useState({ data: [] })


  const [values, setValues] = React.useState({ data: [] })
  const [mod, setMod] = React.useState([])
  const [cifs, setCifs] = React.useState([])
  const [allcif, setAllcif] = React.useState([])

  var ListProductsSubP = []
  var ListCIFforDays = []
  var ListMP = []
  var cifArray = []

  const invoiceSubtotal = subtotal(values.data);
  const modSubtotal = subtotalmod(mod);
  const cifSubtotal = subtotalcif(allcif);
  
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  function ccyFormat(num) {
    return `${formatter.format(num)}`;
  }


  function subtotal(items) {
    let st = items.map(({ valormaterial }) => valormaterial).reduce((sum, i) => sum + i, 0);
    Total = Total + st;
    return st
  }

  function subtotalmod(items) {
    let st = items.map(({ costo }) => costo).reduce((sum, i) => sum + i, 0);
    Total = Total + st;
    return st
  }

  function subtotalcif(items) {
    let st = items.map(({ valor }) => valor).reduce((sum, i) => sum + i, 0);
    Total = Total + st;
    return st
  }

  /// funcion que agrupa los elementos
  var groupById = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };



  async function itemsSubP(val) {
   // console.log(val)
    try {
      if (val) {
        //debugger
        val.map((mod, i) => {

          var product = val[i].subproceso;
          var area = '';
          var fecha = '';
          var cantidad = 0;
          let st = mod.data.map(({ controlrendimientoCantidad }) => controlrendimientoCantidad).reduce((sum, i) => sum + i, 0);
          var costo = 0;
          mod.data.map(element => {
           // product = val[i].subproceso
            cantidad = cantidad + (element.controlrendimientoTiempoDuracion / st)
            costo = costo + (element.manoObra / st)
            area = element.areaNombre
            fecha = element.controlRendimientoFechaFinal
          })


          ListProductsSubP.push({
            'producto': product,
            'cantidad': cantidad.toFixed(0),
            'costo': costo,
            'area': area,
            'fecha': fecha
          })

          product = ''; cantidad = 0; costo = 0; area = ''; fecha = ''
        }
        )

        if (mod.length === 0) { return setMod(ListProductsSubP) }


      }
    } catch (error) {
      console.log(error)
    }

  }



  async function MODFtn(values) {
    try {

      var date1 = document.querySelector('#dateTextFieldInit')
      var date2 = document.querySelector('#dateTextFieldFin')
      if (date2.value && date1.value) {
        const functionWithPromise = async item => { //a function that returns a promise   
          console.log(item[0].subProcesoNombre)   
          var data = await axios(`${Setting.hostName}/queryReportProductsbyTasks/${parseInt(item[0].codProductoHijo)}/${date1.value}/${date2.value}`)

          return Promise.resolve({'data' : data.data, 'subproceso' : item[0].subProcesoNombre })
        }

        const anAsyncFunction = async item => {
          return functionWithPromise(item)
        }

        const getData = async () => {
          return Promise.all(values.map(item => anAsyncFunction(item)))
        }

        const Get_data = await getData()

        var is = await itemsSubP(Get_data);
        ///agrupo los elemento de las MP por nombre
       // var cftn = await CIFFtn(Get_data)

        return true
      }
    } catch (error) {
      console.log(error)
    }



  }



/* 
  const CIFFtn = async (valuesArr) => {
    try {

      var day;

      const functionWithPromise_ = async item => { //a function that returns a promise
        //console.log(item)
        ListCIFforDays.push(item)
        return []

      }
      const anAsyncFunction_ = async item => {
        return functionWithPromise_(item)
      }

      const functionWithPromise = async item => { //a function that returns a promise 
        return Promise.all(item.map(elem => anAsyncFunction_(elem)))
      }

      const anAsyncFunction = async item => {
        return functionWithPromise(item)
      }
      const getData = async () => {
        return Promise.all(valuesArr.map(item => anAsyncFunction(item)))
      }

     const data = await getData()

      var ListCIFs = []


        var sumCIFs = []


        /// agrupo por fechas todas las tareas

        var groubedByID = await groupById(ListCIFforDays, "controlRendimientoFechaFinal");
        //ListCIFforDays = []

        await ListCIFs.push(Object.values(groubedByID))


        const function2 = async (ListCIFs) => {

          var arr = new Array()
          await ListCIFs[0].forEach(async element => {

            //console.log(element)
            await element.forEach(async mod => {
              // filtro todos los hijo para hacer una suma del valor de los materiales cosumidos
              var data_filter = await values.data.filter(element => parseInt(element.productsCodHijo) === parseInt(mod.controlRendimientoProductoCodigo))

              let st = await data_filter.map(({ valormaterial }) => valormaterial).reduce((sum, i) => sum + i, 0);
              // console.log(st)
              arr.push(JSON.stringify({
                "day": mod.controlRendimientoFechaFinal,
                "productCod": mod.controlRendimientoProductoCodigo,
                "idTaks": mod.idcontrolRendimiento,
                "mod": mod.manoObra,
                "mp": st * mod.controlrendimientoCantidad,
                "unit": mod.controlrendimientoCantidad,
                "subProceso": mod.subProcesoNombre
              }))


            })

          })


          return arr

        }


        const b = await function2(ListCIFs)

        const c =async (ListCIFs,b) =>{const datos= await SubCif(ListCIFs, b)
        return datos
        }        




    } catch (error) {
      console.log(error)
    }
    //console.log(values)

  }

  async function groupCif(values, arrs) {


    try {
      values.forEach(async elemento => {
        var month = new Date(elemento[0].controlRendimientoFechaFinal).getMonth() + 1;
        var year = new Date(elemento[0].controlRendimientoFechaFinal).getFullYear();
        /// traigo todos los cif diarios.
        const data = await axios(`${Setting.hostName}/getcifCostsDayCurrentMonth/${month}/${elemento[0].areaNombre}/${year}`)
        // creo un array con el dia y los valores de los cif
  
        var data_filter = await arrs.filter(item => item.day === elemento[0].controlRendimientoFechaFinal)
  
        let stMOD = await data_filter.map(({ mod }) => mod).reduce((sum, i) => sum + i, 0);
        let stMP = await data_filter.map(({ mp }) => mp).reduce((sum, i) => sum + i, 0);
  
        var cifCosto = 0
  
  
  
        data_filter.map(elmt => { 
         
  
          data.data.forEach(item => {
  
            /// formula del cifs diario por tarea
            cifCosto = (item.cif_costosDia / (stMOD + stMP)) * (elmt.mp + elmt.mod)
            //creo el array de los cifs
  
            cifArray.push({
              "nombre": item.cifDescripcion,
              "valor": cifCosto,
              "cantidad": elmt.unit,
              "subProceso": elmt.subProceso
            })
          })
  
  
  
          cifCosto = 0;
  
  
        })
  
  
        var arrCIFgroupSubpro = []
       
        //// agrupo por subproceso
        var groubedBySP = await groupById(cifArray, "subProceso");
  
        var OBJCifsGroup = Object.values(groubedBySP)
  
        OBJCifsGroup.forEach(element => {
  
          var groubed = groupById(element, "nombre");
          var OBJ = Object.values(groubed)
          // console.log( groubedByID)
  
          var costo = 0
          var Numcantidadestareas = 0
          var nombre = ''
  
          OBJ.forEach(item => {
  
            item.forEach(element => {
              costo = costo + element.valor
              Numcantidadestareas = Numcantidadestareas + element.cantidad
              nombre = element.nombre
            });
  
            arrCIFgroupSubpro.push({
              "nombre": nombre,
              "valor": costo / Numcantidadestareas,
            })
  
            costo = 0; Numcantidadestareas = 0; nombre = '';
  
          })
  
  
  
        })
  
  
  
        if (cifs.length === 0) { return setCifs(arrCIFgroupSubpro); }
        
        return true
      })
    } catch (error) {
      console.log(error) 
    }

    


  }


  const SubCif = async (a, b) => {
  
 try {
  var OBJCifs
  var arr = new Array()

  b.forEach(element => {
    arr.push(JSON.parse(element))
  });

  
  // var objCif=[]
  
 // const GCIF = await groupCif(a[0], arr)

  return true

 } catch (error) {
   console.log(error)
 }
  }
 */


 const removeElements = () =>{
   setMod([])
   setSelectedDate()
   setSelectedDatetwo()
   setSelectedDatetwo()
 }

  const GetElements = async () => { //// trae todos los elementos hijos del producto seleccionado.
   // setMod([])
    var date1 = document.querySelector('#dateTextFieldInit')
    var date2 = document.querySelector('#dateTextFieldFin')

    setSelectedDate(date1.value);
    setSelectedDatetwo(date2.value);


    const data = await axios(`${Setting.hostName}/queryReportProducts/${productsFather}`);


    var groubedByID = await groupById(data.data, "productsCodHijo");

    setValues(data);

    ListMP.push(Object.values(groubedByID))

    const MIMOD = await MODFtn(Object.values(groubedByID));
    

  }


  const Reports = async () => { /// carga la lista de productos padre.

    
    const products = await axios(`${Setting.hostName}/productsfhaters`);
    setProductsFathers(products)
    /// componentDidMount


  }

  React.useEffect(() => {
    Reports();
  }, []);



  const handleChange = event => {
    setProductsFather(event.target.value)
    setMod([])
  }





  return(
<>

<Grid container justify="space-around"> 


<TextField
            id="area"
            select
            label="Productos"
            className={classes.textField}
            value={productsFather}
            onChange={handleChange}
            style={{ margin: 8, width: '30%' }}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}

            margin="normal"
            variant="outlined"
          >
            {productsFathers.data.map((option, i) => (
              <MenuItem key={i} value={option.productosCodigo}>
                {option.productosNombre}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id="dateTextFieldInit"
            label="Fecha inicio"
            type="date"
            defaultValue={selectedDate}
            pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            id="dateTextFieldFin"
            label="Fecha fin"
            type="date"
            defaultValue={selectedDatetwo}
            pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button variant="outlined" className={classes.button} onClick={() => GetElements()} >
            Generar Reporte
   </Button>

   <Button variant="outlined" className={classes.button} onClick={() => removeElements()} >
            Borrar Reporte
   </Button>

{mod.length > 0 &&


<TableContainer component={Paper}>

<ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-report-mod"
                    filename="reportMOD"
                    sheet="tablexls"
                    buttonText="descargar as XLS"/>


<Table className={classes.table} aria-label="spanning table" id="table-report-mod">
  <TableHead>
    <TableRow>



    </TableRow>


    <TableRow>

      <TableCell align="center" colSpan={3}>
        DETALLES
    </TableCell>
      <TableCell align="right">VALORES</TableCell>
    </TableRow>
    <TableRow>
    
      <TableCell style={{ backgroundColor: '#cccc', color: '#000' }} >DESCRIPCIÓN</TableCell>
      <TableCell style={{ backgroundColor: '#cccc', color: '#000' }} align="right">UM</TableCell>
      <TableCell style={{ backgroundColor: '#cccc', color: '#000' }} align="right">CANTIDAD</TableCell>
      <TableCell style={{ backgroundColor: '#cccc', color: '#000' }} align="right">COSTO</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    
    {mod?.map((row, i) => (
      <TableRow key={i}>        
        <TableCell colSpan={1} >{row.producto}</TableCell>
        <TableCell align="right" >MIN.</TableCell>
        <TableCell align="right">{row.cantidad}</TableCell>
        <TableCell align="right">{ccyFormat(row.costo)}</TableCell>
      </TableRow>
    ))}

     


    <TableRow >
      <TableCell style={{ backgroundColor: '#000ccf', color: '#fff' }} colSpan={3}>SUB TOTAL COSTO MOD</TableCell>
      <TableCell style={{ backgroundColor: '#000ccf', color: '#fff', fontSize: '20px', fontWeight: 'bold' }} align="right">{ccyFormat(modSubtotal)}</TableCell>
    </TableRow>
  </TableBody>
</Table>
</TableContainer>

}

</Grid>
</>

  )
}
