import React, {useContext, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Divider from '@material-ui/core/Divider';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import ProductIcon from '@material-ui/icons/PlaylistAddCheck'
import ThreadIcon from '@material-ui/icons/LowPriority'
import BookIcon from '@material-ui/icons/Book';
import BusinessIcon from '@material-ui/icons/Business';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import AssessmentRoundedIcon from '@material-ui/icons/AssessmentRounded';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
///

import {AppContext} from '../contextApi/Context'

import { Link } from "react-router-dom";
import ListSubItems from './ListSubItems'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
export default function ListItems(props) {
  const classes = useStyles();
  const {userData, socket} = useContext(AppContext);

  if (userData.rolesNombre === "Administrador") {
    return (

      <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      
      className={classes.root}
    >

<ListSubItems subItems={[
  {nombre:'Inicio', path:'/dashboard', icon: <DashboardIcon /> },
  {nombre:'Usuarios', path:'/users', icon: <PeopleIcon /> },
  {nombre:'Productos', path:'/products', icon: <ProductIcon /> },
  {nombre:'Descuentos', path:'/Disscounts', icon: <LayersIcon /> },
  {nombre:'Subprocesos', path:'/thread', icon: <BookIcon /> },
  {nombre:'Áreas', path:'/areas', icon: <ThreadIcon /> },
  {nombre:'Sucursales', path:'/branch', icon: <BusinessIcon /> },
  {nombre:'Generador Tiempos Estandar', path:'/standarstimes', icon: <TrendingUpOutlinedIcon /> }
]}  
  text="Administración" icon={<BusinessCenterIcon />}/>

  {/* <ListSubItems subItems={[
  {nombre:'Cargar Productos', path:'/chargeProducts', icon: <CompareArrowsIcon /> },
  
]}  
  text="Configuración"  icon={<SettingsApplicationsIcon />}/>           
<Divider /> */}

 <ListSubItems subItems={[
  {nombre:'Consulta Tiempos', path:'/ReportTimes', icon: <BarChartIcon /> },
  {nombre:'Consulta Costos', path:'/ReportCosts', icon: <BarChartIcon /> },
  {nombre:'Consulta Descuentos', path:'/ReportDisscounts', icon: <BarChartIcon /> },
  // {nombre:'CIF', path:'/CIF', icon: <BarChartIcon /> },  
  // {nombre:'Materiales consumidos', path:'/AllmaterialesConsumers', icon: <BarChartIcon /> }, 
 {nombre:'Ajuste Tiempos', path:'/ChangeTimers', icon: <BarChartIcon /> },
 {nombre:'Ajuste Tareas', path:'/ChangeTasks', icon: <BarChartIcon /> },
  {nombre:'Tiempos por producto', path:'/reporttasks', icon: <BarChartIcon /> },
  {nombre:'Costo Producto', path:'/reportotalcosto', icon: <BarChartIcon /> }, 
  
  
  
]}  
  text="Reportes" icon={<AssessmentRoundedIcon />} />      
    
      
    </List>
    )
  } else {

    return null
  }


}
