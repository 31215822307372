import React, {useContext} from 'react'
import Settings from "../../settings";
import axios from "axios";
import { AppContext } from "../../../contextApi/Context";

import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import ReplayIcon from "@material-ui/icons/Replay";


function reinitial({
    idcontrolRendimiento,
    FechadePausado,
    TiempoConsumido,
    status,
    creador,
    idUser,
    allTasks
}) {


    const {    
        userData, socket
      } = useContext(AppContext);




    async function _restarted(id, datepause, totalparcial) {
     
      let NewTotalParcial= 0
      const TIMEEXPIRATION = Settings.tiempoduracion;
        if (status !== "Iniciado") {
          const dtimezone = new Date().getTimezoneOffset() * 60000;
          const TodaylocalIsoTime = new Date(Date.now() - dtimezone).toISOString();

          let localstorage = localStorage.getItem(idUser);


          if(localstorage){    



            var TiempoInicial =  new Date(JSON.parse(localstorage).fechaCreado).getTime();
            var TiempoActual = (new Date().getTime()) - dtimezone;
            var diff = TiempoActual - TiempoInicial;
            var TiempoDuracionTarea = parseFloat(diff / 60000).toFixed(0);
      
           // 
            if(parseInt(TiempoDuracionTarea) <= TIMEEXPIRATION ){
                          
              NewTotalParcial = (parseInt(TiempoDuracionTarea) + totalparcial).toFixed(0)
            }
            
        }
          


        const RestartApi =await  axios.put(`${Settings.hostName}/performance/${id}`,{
          id: id,
          fechaFinal: "",

          fechaPausa: datepause,
          estado: 5,
          duraciontarea: totalparcial,
          fechareinicio:
            TodaylocalIsoTime === undefined
              ? new Date(Date.now() - dtimezone).toISOString()
              : TodaylocalIsoTime,
          idUSer:idUser
        })

        if(RestartApi.status === 200){
          socket.emit('reinicio',{
            name:userData.empleadosNombre,
            mensaje: 'Reinicio la tarea',
            data: idUser,
            room: userData.cedulaEncargado === 0 ?  creador : userData.cedulaEncargado})
            localStorage.removeItem(idUser);
        }

          // agregar los datos de descuentos
          
          
        }
      }
    




  return (
    <Grid item xs={2}>
            <IconButton
              aria-label="Reiniciar"
              color="inherit"
              title="Reiniciar"
              disabled={status === "Pendiente"? false:true }
              onClick={() =>
                _restarted(
                  idcontrolRendimiento,
                  FechadePausado,
                  TiempoConsumido
                )
              }
            >
              <ReplayIcon />
            </IconButton>
          </Grid>
  )
}

export default reinitial