import React from 'react';  import Settings from '../settings';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';



import { SnackbarProvider, useSnackbar } from 'notistack';



const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '7%'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    button: {
        margin: theme.spacing(1),
    },

}));



function MyAppForm(props) {

    const classes = useStyles();

    const [values, setValues] = React.useState({
        nombre: '',     
        

    });
   
    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });

    };

    const { enqueueSnackbar } = useSnackbar();

    const handleClick = () => {
        enqueueSnackbar('Área Editado.', {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
        });
    };


    const handleSave = (e) => {
        editDisc()
        e.preventDefault();
    }

     
    
    const editDisc = () => {
        /// componentDidMount          

        const id = parseInt(props.id);
        if (values.nombre === '') {            
            

            fetch(`${Settings.hostName}/areas/${id}`,
                {
                    method: 'GET',
                    mode: 'cors',
                    cache: 'default',

                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
                .then(res => res.json())
                .then(
                    (result) => {                                                
                        setValues({                            
                            nombre: result.areaNombre                                                                                  
                        })

                    },
                    (error) => {
                        console.log(error)
                    }
                )


        }
        else {            
            axios({
                method: 'put',
                url: `${Settings.hostName}/areas/${id}`,
                data: {
                    
                    "nombre": values.nombre, 

                }
            })
                .then(
                    (result) => {
                        handleClick();
                        props.users();
                    },
                    (error) => {
                        console.log(error)
                    }
                )

        }

    }

    React.useEffect(() => {
        editDisc();
    }, []);




    return (
        <React.Fragment>
    <form className={classes.container}  autoComplete="off" onSubmit={handleSave}>
    <TextField
          id="nombre"
          label="Nombre"
          style={{ margin: 8 }}
          placeholder="Digita el nombre"
          fullWidth
          value={values.nombre}
          onChange={handleChange('nombre')}
          
          margin="normal"
          required
        />

        
        
        <Button type="submit" variant="contained" color="primary" className={classes.button}>
          Aceptar
      </Button>

      </form>
    
          </React.Fragment>
    );
}



export default function EditAreas(props) {
    return (
        <SnackbarProvider maxSnack={1} >
            <MyAppForm users={props.addnewsUser} id={props.id} />
        </SnackbarProvider>
    );
}