import React, {useContext} from "react";

import { AppContext } from "../../../contextApi/Context";

import { green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";


import CheckIcon from "@material-ui/icons/Check";

import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";


import CardActions from "@material-ui/core/CardActions";

//descuentos
import AddDisscounts from "../AddDisscounts";
import DetailsDisscount from "../DetailsDisscounts";
import Reasing from "../ReAsingTask";

import Done from './Done'
import Pause from './pause'
import Reinitial from './reinitial'
import Delete from './delete'



const useStyles = makeStyles((theme) => ({
    card: {
      maxWidth: 345,
    },
    grid: {
      marginBottom: 25,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: green[500],
    },
    ini: {
      backgroundColor: "green",
    },
    fin: {
      backgroundColor: "blue",
    },
    pen: {
      backgroundColor: "orange",
    },
    rec: {
      backgroundColor: "red",
    },
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }));
  

/*  
listen={listenBottons}
                element={element}
                refresh={refresh}
                allrefresh={props.refresh}
                status={element.EstadoNombre}
                disable={disable}
                pause={statuspausado}
                timetaks={element.controlrendimientoTiempoDuracion}
                openNewTask={openAutomateTask}
                users={users}
                rol={userData.rolesNombre}
                estadotareas={iniciado}
                myuser={userData} */
export default function BottonsPanel(props){

    const {    
        userData, socket
      } = useContext(AppContext);
      
      const classes = useStyles();

      const [expanded, setExpanded] = React.useState(false);
      function handleExpandClick() {
        setExpanded(!expanded);
        //GetListMyDisscounts();
      }



    return(
        <>
       <CardActions disableSpacing>
        <Grid container spacing={1}>
          <Reasing
            users={props.users.data}
            id={props.element.idcontrolRendimiento}
            refresh={props.refresh}
            disabled={props.disable}
            room={props.element.empleadosCedula}
            oldUser={props.element.controlRendimientoEmpleadoId}            
          />


<Done 
idcontrol={props.element.idcontrolRendimiento} fechaInicio={props.element.controlRendimientoFechaInicio} TiempoDuracion={props.element.controlrendimientoTiempoDuracion} fechaReinicio={props.element.controlrendimientoFechaReinicio} status={props.status} creador={props.element.empleadosCedula} idUser={props.element.controlRendimientoEmpleadoId} openTask={props.openNewTask}
/>

<Pause
idcontrol={props.element.idcontrolRendimiento} fechaInicio={props.element.controlRendimientoFechaInicio} TiempoDuracion={props.element.controlrendimientoTiempoDuracion} fechaReinicio={props.element.controlrendimientoFechaReinicio} status={props.status} creador={props.element.empleadosCedula} idUser={props.element.controlRendimientoEmpleadoId}
/>

<Reinitial 
idcontrolRendimiento={props.element.idcontrolRendimiento}
FechadePausado={props.element.controlRendimientoFechaPausa}
TiempoConsumido={props.element.controlrendimientoTiempoDuracion}
status={props.status}
creador={props.element.empleadosCedula}
idUser={props.element.controlRendimientoEmpleadoId}
/>

<Grid item xs={3}>
            <AddDisscounts              
              idControl={props.element.idcontrolRendimiento}              
            />
          </Grid>

<Delete 
refresh={props.refresh} 
idcontrolRendimiento={props.element.idcontrolRendimiento} 
creador={props.element.empleadosCedula} 
idUser={props.element.controlRendimientoEmpleadoId}
/>
            <Grid item xs={12}>
            <IconButton
              title="Ver Descuentos"
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="Ver Descuentos"
            >
              <ExpandMoreIcon /> 
            </IconButton>
            <span onClick={handleExpandClick} style={{ cursor:'pointer'}}> ver descuentos</span>
          </Grid>

              <DetailsDisscount
        //refresh={refresh}
        idControlTask={props.element.idcontrolRendimiento}
        expanded={expanded}
        //data={mydiscounts}
       // total={total}
      />

          </Grid>
      </CardActions>
        </>
    )
}