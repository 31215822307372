import React from 'react';  import Settings from '../settings';
import axios from 'axios';
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));




export default function Branchs(props) {
    const classes = useStyles();
    const [data, setData] = React.useState({ data: [] })
    const [columns, setColumns] = React.useState({
        data: [
            { title: 'id', field: 'idsucursales', editable: 'never' },
            { title: 'Nombre', field: 'sucursalesNombre' },            
        ]
    })

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    /// load data
    const LoadData = async () => {
        const data = await axios(`${Settings.hostName}/branch`, { cancelToken: source.token })
        setData(data)
    }

    React.useEffect(() => {
        LoadData();
        return () => {
            source.cancel();
        };
    }, [])

    /// add data
    function add(data) {
        axios({
            method: 'post',
            url: `${Settings.hostName}/branch/add`,
            data: {
                "nombre": data.sucursalesNombre,
                
            }
        })
            .then(
                (res) => {
                    LoadData()
                    
                }, (err) => {
                    console.log(err)
                }

            )
    }
    // edit data

    function edit(data) {
        var id = data.idsucursales;
        axios({
            method: 'put',
            url: `${Settings.hostName}/branch/${id}`,
            data: {
                "nombre": data.sucursalesNombre,                
            }
        })
            .then(
                (res) => {
                    LoadData()
                    
                }, (err) => {
                    console.log(err)
                }

            )

    }


    function onDelete(data) {
        var id = data.idsucursales;
        axios({
            method: 'delete',
            url: `${Settings.hostName}/branch/${id}`
        })
            .then(
                (result) => {
                    LoadData()
                    
                },
                (error) => {
                    console.log('error')
                }
            )
    }

    return (
        <MaterialTable
            title=" "
            columns={columns.data}
            data={data.data}
            localization={{
                body: {
                  emptyDataSourceMessage: 'No hay Datos',
                  editRow:{
                    deleteText:'¿Estás seguro de eliminar esté elemento?'
                  },
                },
                toolbar: {
                  searchTooltip: 'Busqueda',
                  searchPlaceholder: 'Buscar...'
                },
                header:{
                    actions: 'Editar/Eliminar'
                },
                pagination: {
                  labelRowsSelect: 'Filas',
                  labelDisplayedRows: ' {from}-{to} de {count}',
                  firstTooltip: 'Primero',
                  previousTooltip: 'Anterior',
                  nextTooltip: 'Siguiente',
                  lastTooltip: 'Último'
                }
              }}
            editable={{
                onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            {
                                add(newData)
                            }
                            resolve()
                        }, 500)
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            {
                                edit(newData)
                            }
                            resolve()
                        }, 500)
                    }),
                onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            {
                                onDelete(oldData)
                            }
                            resolve()
                        }, 500)
                    }),
            }}
        />
    )
}