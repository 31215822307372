import React from 'react';  import Settings from '../settings';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { SnackbarProvider, useSnackbar } from 'notistack';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '7%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },

}));



function MyAppForm(props) {

  const classes = useStyles();
  const [values, setValues] = React.useState({    
    nombre: '', 
  });

  
  const [subp, setSubp] = React.useState({ data: [] })

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });

  };

  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    enqueueSnackbar('Subproceso agregado.', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  };

  const handleClickVariant = () => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar('el Subproceso ya esta registrado!', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  };



  const handleSave = (e) => {
   if(values.nombre != ''){
    validateAndAdd();
   } 
    e.preventDefault();
  }

  //const [users, setUsers] =  React.useState({ data:[]}) 

  const validateAndAdd =  async () => {
    /// componentDidMount           

    const codigo = { values };
    const List = await axios(`${Settings.hostName}/thread`);
    setSubp(List);
   

    if (subp.data.length != 0) {
      var data_filter = subp.data.filter(element => element.subProcesoNombre === codigo.values.nombre) /// filtro el password del usuario en todos los usuarios.
      // validar si el usuario ya existe en la base de datos
      if (data_filter.length > 0) {
        handleClickVariant();
        return false

      }
      else {

         axios({
          method: 'post',
          url: `${Settings.hostName}/thread/add`,
          data: {
           
            "nombre": values.nombre,           
                    

          }
        })
          .then(
            (res) => {
              setValues({                
                nombre: '',                
                              
              });

              handleClick();
              props.users();
            }, (err) => {
              console.log(err)
            }

          )
        
      }
    }



  }

  React.useEffect(() => {
    validateAndAdd();
  }, []);




  return (
    <React.Fragment>
      <form className={classes.container}  autoComplete="off" onSubmit={handleSave}>
        <TextField
          id="nombre"
          label="Nombre"
          style={{ margin: 8 }}
          placeholder="Digita el nombre"
          fullWidth
          value={values.nombre}
          onChange={handleChange('nombre')}
          
          margin="normal"
          required
        />

        
        
        <Button type="submit" variant="contained" color="primary" className={classes.button}>
          Aceptar
      </Button>

      </form>
    </React.Fragment>
  );
}






export default function AddThread(props) {

  return (


    <SnackbarProvider maxSnack={2} >

      <MyAppForm users={props.addnewsUser} />

    </SnackbarProvider>

  );
}