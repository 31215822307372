import React from 'react';  import Settings from '../settings';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import QRCode from 'qrcode.react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import { SnackbarProvider, useSnackbar } from 'notistack';


const genero = [
  {
    value: '1',
    label: 'Hombre',
  },
  {
    value: '2',
    label: 'Mujer',
  }

];

const rol = [
  {
    value: '1',
    label: 'Administrador',
  },
  {
    value: '2',
    label: 'Operario',
  },
  {
    value: '3',
    label: 'Supervisor',
  }

];

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '1%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: '15%'
  },
  paperRight: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: '15%'
  },

}));



function MyAppForm(props) {

  const classes = useStyles();
  const [values, setValues] = React.useState({
    cedula: '',
    nombre: '',
    apellidos: '',
    areaid: 0,
    generoid: '',
    rolid: 0,
    costominuto:0,
    cedulaEncargado:0,   

  });


  const [users, setUsers] = React.useState({ data: [] })
  const [areas, setAreas] = React.useState({ data: [] })
 

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });

  };

  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    enqueueSnackbar('Usuario agregado.', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  };

  const handleClickVariant = () => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar('La cedúla ya esta registrada!', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  };



  const handleSave = (e) => {
    validateUser();

    e.preventDefault();
  }

  //const [users, setUsers] =  React.useState({ data:[]}) 

  const validateUser = async () => {
    /// componentDidMount           

    const cedula = { values };
    

    function getUsers() {
      return axios.get(`${Settings.hostName}/users`);
    }

    function getArea() {
      return axios.get(`${Settings.hostName}/area`);
    }
    

    axios.all([getUsers(), getArea()])
      .then(axios.spread(function (listUsers, listArea) {
        // Both requests are now complete
        setUsers(listUsers);
        setAreas(listArea);
      }));


    if (users.data.length !== 0) {
      var data_filter = users.data.filter(element => element.empleadosCedula === (cedula.values.cedula).toString) /// filtro el password del usuario en todos los usuarios.
      // validar si el usuario ya existe en la base de datos
      if (data_filter.length > 0) {
        handleClickVariant();
        return false

      }
      else {

        await axios({
          method: 'post',
          url: `${Settings.hostName}/users/add`,
          data: {

            "cedula": parseInt(values.cedula),
            "nombre": values.nombre,
            "apellido": values.apellidos,
            "area": values.areaid,
            "genero": values.generoid,
            "rol": values.rolid,
            "costominuto":values.costominuto,
            "cedulaEncargado": values.cedulaEncargado

          }
        })
          .then(
            (res) => {
              setValues({
                cedula: '',
                nombre: '',
                apellidos: '',
                areaid: 0,
                generoid: '',
                rolid: 0,
                costominuto:0,
                cedulaEncargado:0
              })
            }, (err) => {
              console.log(err)
            }

          )
        handleClick();
        props.users();
      }
    }



  }

  React.useEffect(() => {
    validateUser();
  }, []);




  return (
    <React.Fragment>      
       <Container fixed>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6} sm={6}>
          <Paper className={classes.paper}>
          <form className={classes.container} noValidate autoComplete="off" onSubmit={handleSave}>
        <TextField
          id="cedula"
          label="Cédula"
          style={{ margin: 8 }}
          placeholder="Digita la cedula"
          fullWidth
          value={values.cedula}
          onChange={handleChange('cedula')}
          type="number"
          margin="normal"
          variant="outlined"
          
        />

        <TextField
          id="nombre"
          label="Nombre"
          style={{ margin: 8 }}
          placeholder="Digita el Nombre"
          fullWidth
          margin="normal"
          variant="outlined"
          value={values.nombre}
          onChange={handleChange('nombre')}
          
        />

        <TextField
          id="apellidos"
          label="Apellidos"
          style={{ margin: 8 }}
          placeholder="Digita los Apellidos"
          fullWidth
          margin="normal"
          variant="outlined"
          value={values.apellidos}
          onChange={handleChange('apellidos')}
          
        />      

        <TextField
          id="costominuto"
          label="Costo Minuto"
          style={{ margin: 8 }}
          placeholder="Digita el valor costor por minuto"
          fullWidth
          margin="normal"
          variant="outlined"
          value={values.costominuto}
          onChange={handleChange('costominuto')}
          
        />
        <TextField
          id="area"
          select
          label="Seleccionar Área"
          className={classes.textField}
          value={values.areaid}
          onChange={handleChange('areaid')}
          fullWidth
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}

          margin="normal"
          variant="outlined"
        >
          {areas.data.map(option => (
            <MenuItem key={option.idarea} value={option.idarea}>
              {option.areaNombre}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="genero"
          select
          label="Seleccionar Género"
          className={classes.textField}
          value={values.generoid}
          onChange={handleChange('generoid')}
          fullWidth
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}

          margin="normal"
          variant="outlined"
        >
          {genero.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="rol"
          select
          label="Seleccionar Permiso"
          className={classes.textField}
          value={values.rolid}
          fullWidth
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
          margin="normal"
          variant="outlined"
          onChange={handleChange('rolid')}
        >
          {rol.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        
        <TextField
          id="cedulaEncargado"
          label="Cédula Encargado"
          style={{ margin: 8 }}
          placeholder="Digita la cédula del encargado"
          fullWidth
          margin="normal"
          variant="outlined"
          value={values.cedulaEncargado}
          onChange={handleChange('cedulaEncargado')}
          
        />
            <Button type="submit" variant="contained" color="primary" className={classes.button}>
              Aceptar
          </Button>
         
      </form>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6} sm={6}>
          <Paper className={classes.paperRight}>
          <QRCode value={values.cedula}  level='H' />
          </Paper>
        </Grid>        
      </Grid>
      </Container>

    </React.Fragment>
  );
}






export default function AddUser(props) {

  return (


    <SnackbarProvider maxSnack={2} >

      <MyAppForm users={props.addnewsUser} />

    </SnackbarProvider>

  );
}