import 'date-fns';
import esLocale from "date-fns/locale/es/";
import React, { Component } from 'react';import Settings from '../settings';
import axios from 'axios'
import MaterialTable from 'material-table'
import MTableToolbar from 'material-table/dist/components/m-table-toolbar'
import Moment from 'react-moment'
import 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
////table

import Paper from '@material-ui/core/Paper';

// dialogo
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

///filter
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: '100%',
  },
  appBar: {
    position: 'relative',
    backgroundColor: '#4C0066',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
})); 

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function SimpleDialog(props) {
  const classes = useStyles();

  const { onClose, open } = props;

  function handleClose() {
    onClose();
  }

  function handleListItemClick(value) {
    onClose(value);
  }

  if (props.group) {

    return (

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>

          </Toolbar>
        </AppBar>
        <Paper className={classes.root}>
          <MaterialTable
            title="Total Costos Agrupados"
            data={props.data}
            columns={[
              { title: 'Grupo 1', field: 'path', },
              { title: 'Grupo 2', field: 'child', },              
              { title: 'Total Tiempo de Descuento', field: 'cantidad', removable: false },
              { title: 'Totales Costo Descuentos', field: 'totalDescuentos' },

            ]}
            parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
            options={{
              exportButton: true,
              search: false,
              pageSize: 50,
              pageSizeOptions: [10, 50, 100, 200, 500, 1000, 2000],
              exportAllData: true
            }}
          />
        </Paper>
      </Dialog>
    )
  }

  if (props.data.length === undefined) {
    return null
  }
  else {

    return (
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>

          </Toolbar>
        </AppBar>
        <Paper className={classes.root}>
          <MaterialTable
            title="Total Costos"
            data={props.data}
            columns={[
              { title: 'Grupo', field: 'path' },              
              { title: 'Total Tiempo de Descuento', field: 'cantidad', removable: false },
              { title: 'Totales Costo Descuentos', field: 'totalDescuentos' },
             
            ]}
            options={{
              exportButton: true,
              search: false,
              pageSize: 50,
              pageSizeOptions: [10, 50, 100, 200, 500, 1000, 2000],
              exportAllData: true
            }}
          />
        </Paper>
      </Dialog>
    );
  }


}
SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};




export default function App () {
    const classes = useStyles();
    const [values, setValues] = React.useState({data:[]})
    const [selectedrow, setSelectedrow] = React.useState(null)
    const [totalesdata, setTotalesdata] = React.useState({})
    const [groups, setGroups] = React.useState(false)
    const [open, setOpen] = React.useState(false);

    const [selectedDate, setSelectedDate] = React.useState();
  const [selectedDatetwo, setSelectedDatetwo] = React.useState();

  const [btnDisabled, setBtnDisabled] = React.useState(false)

  function handleDateChange(date) {    
    var anio = new Date(date).getFullYear()
    var month = new Date(date).getMonth() + 1
    if(month < 10){
      month = `0${month}`
    }
    var day = new Date(date).getDate() === 31 ? new Date(date).getDate() : new Date(date).getDate() + 1;
    if(day <10){
      day = `0${day}`
    }
    const fecha = `${anio}-${month}-${day}`
    setSelectedDate(fecha);
  }
  function handleDateChangeTwo(date) {
    var anio = new Date(date).getFullYear()
    var month = new Date(date).getMonth() + 1
    if(month < 10){
      month = `0${month}`
    }
    var day = new Date(date).getDate() === 31 ? new Date(date).getDate() : new Date(date).getDate() + 1;
    if(day <10){
      day = `0${day}`
    }
    const fecha = `${anio}-${month}-${day}`
 
    setSelectedDatetwo(fecha);
    setBtnDisabled(false);
  }
  const handleClose = value => {
    setOpen(false);
    setGroups(false);
  };

  const filter= async () =>{

    var date1 = document.querySelector('#dateTextFieldInit')
    var date2 = document.querySelector('#dateTextFieldFin')
    setSelectedDate(date1.value);
    setSelectedDatetwo(date2.value);   

    if(date1.value && date2.value){
      const data = await axios(`${Settings.hostName}/queryReportFilterDisscounts/${date1.value}/${date2.value}`);
    setValues(data);
    }else{
      alert('los campos de fecha de inicio y final son requeridos')
    }
    
  }
  const emptyData = () =>{
    setValues([])
  }

    
    const Reports = async () => {
        /// componentDidMount
        const data = await axios(`${Settings.hostName}/queryReportDisscounts`);
        setValues(data);
      }
      React.useEffect(() => {
        //Reports()
      },[]);
    
      const Format = (props) =>{                
        return (
          `${Math.trunc(props.value)}%`
        )
    }

    const FormatReal = (props) =>{                
      return (
        `$ ${Math.trunc(props.value)}`
      )
  }
   
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 1
  })
  
function sumaTotales(values) {

    var large = values.length
    var dataTotales = [];
    if (large > 0) {
      setOpen(true);
      var totalValorPredeterminado = 0; var totalCantidad = 0; var totalDescuentosCosto = 0;;
      var totalValorPredeterminadoSum = 0; var totalCantidadSum = 0; var totalDescuentosCostoSum = 0; ;
      if (values[0].path != undefined) {
        if (values[0].groups.length > 0) {
          for (var i = 0; large > i; i++) {
            var dataPadre = values[i].groups
            var path = values[i].value
            //dataTotales.push({ 'id': i, 'path': path })
            for (var j = 0; dataPadre.length > j; j++) {
              var dataChild = dataPadre[j].data;
              var pathChild = dataPadre[j].value
              for (var k = 0; dataChild.length > k; k++) {
                totalValorPredeterminado = totalValorPredeterminado + dataChild[k].descuentosValor;
                totalCantidad = totalCantidad + dataChild[k].TotalMinutosDescuento;
                totalDescuentosCosto = totalDescuentosCosto + dataChild[k].TotalDescuento;
                
              }
              totalValorPredeterminadoSum = totalValorPredeterminadoSum + totalValorPredeterminado;
              totalCantidadSum = totalCantidadSum + totalCantidad;
              totalDescuentosCostoSum = totalDescuentosCostoSum + totalDescuentosCosto;
              


              dataTotales.push({
                'id': Math.floor(Math.random() * (999 - 0)) + 0,
                'parentId': i,
                'path': path,
                'child': pathChild,
                'valorPredeterminado': totalValorPredeterminado,
                'cantidad': totalCantidad,
                'totalDescuentos': formatter.format(totalDescuentosCosto),
                
              });

              totalValorPredeterminado = 0;
              totalCantidad = 0;
              totalDescuentosCosto = 0;             

            }

            dataTotales.push({
              'id': i, 'path': path,
              'valorPredeterminado': totalValorPredeterminadoSum,
                'cantidad': totalCantidadSum,
                'totalDescuentos': formatter.format(totalDescuentosCostoSum),
            })

            totalValorPredeterminadoSum = 0; totalCantidadSum = 0; totalDescuentosCostoSum = 0; 

          }
          setTotalesdata(dataTotales)
          setGroups(!groups)

        } else {
          for (var i = 0; large > i; i++) {
            var data = values[i].data
            var path = values[i].path[0]
            for (var j = 0; data.length > j; j++) {

              totalValorPredeterminado = totalValorPredeterminado + data[j].descuentosValor;
              totalCantidad = totalCantidad + data[j].TotalMinutosDescuento;
              totalDescuentosCosto = totalDescuentosCosto + data[j].TotalDescuento;              

            }
            dataTotales.push({
              'path': path,
              'valorPredeterminado': totalValorPredeterminado,
          'cantidad': totalCantidad,
          'totalDescuentos': formatter.format(totalDescuentosCosto),
            });

            totalValorPredeterminado = 0;
            totalCantidad = 0;
            totalDescuentosCosto = 0;
            

          }
          setTotalesdata(dataTotales)
        }

      }
      else {
        for (var i = 0; large > i; i++) {
          totalValorPredeterminado = totalValorPredeterminado + values[i].descuentosValor;
          totalCantidad = totalCantidad + values[i].TotalMinutosDescuento;
          totalDescuentosCosto = totalDescuentosCosto + values[i].TotalDescuento;         
        }
        dataTotales.push({
          'path': 'Total',
          'valorPredeterminado': totalValorPredeterminado,
          'cantidad': totalCantidad,
          'totalDescuentos': formatter.format(totalDescuentosCosto),
          
        }
        )
        setTotalesdata(dataTotales)

      }
      setOpen(true);
    }

  }


    return (
      <div>      
      <MaterialTable
          columns={[
            { title: 'No. Tarea', field: 'controlrendimientoID', type: 'numeric',filtering: false, },
            { title: 'Nombre', field: 'empleadosNombre',filtering: false,},
            { title: 'Apellidos', field: 'empleadosApellidos', filtering: false },
            { title: 'Producto', field: 'productosNombre',filtering: false },
            { title: 'Área', field: 'areaNombre',},
            { title: 'Subproceso', field: 'subProcesoNombre',filtering: false  },
            { title: 'Descuento', field: 'descuentosNombre',filtering: false , },            
             { title: 'Total Tiempo', field: 'Valor', type: 'numeric',
             filtering: false  },
             { title: 'Total Costo del Descuento', field: 'TotalDescuento', type: 'numeric',
             render: rowData => (              
                <FormatReal value={rowData.TotalDescuento} />              
             ),filtering: false },
             { title: 'No. Pedido', field: 'controlrendimientoNumeroPedido', filtering: false },
              { title: 'Sucursal', field: 'controlrendimientoSucursal', },
            { title: 'Estado', field: 'EstadoNombre' },
            {
              title: 'Fecha Inicio',
              field: 'controlRendimientoFechaInicio',
              type: 'date',filtering: false
            },
            { title: 'Fecha Final', field: 'controlRendimientoFechaFinal', 
            type: 'date',filtering: false
            },
          ]}
          data={values.data}
          //parentChildData={(row, rows) => rows.find(a => a.controlrendimientoID === row.controlrendimientoID)}
          title="Reporte Consulta Descuentos"          
          options={{
            grouping: true,
            search: true,
            exportButton: true,
            pageSize: 100,
            pageSizeOptions: [10,50,100,200,500,1000,2000],
            rowStyle: rowData => ({
              backgroundColor: (selectedrow && selectedrow.selectedrow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
            }),
            filtering:true 
          }}
          localization={{
            body: {
              emptyDataSourceMessage: 'No hay Datos'
            },
            toolbar: {
              searchTooltip: 'Busqueda',
              searchPlaceholder:'Buscar...'
            },
            grouping:{
              placeholder:'Arrastra aquí la columna para agrupar'
            },
            pagination: {
              labelRowsSelect: 'Filas',
              labelDisplayedRows: ' {from}-{to} de {count}',
              firstTooltip: 'Primero',
              previousTooltip: 'Anterior',
              nextTooltip: 'Siguiente',
              lastTooltip: 'Último'
            }            
          }}
          onRowClick={((evt, selectedrow) => setSelectedrow({ selectedrow }))}
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} /> 
                <SimpleDialog data={totalesdata} group={groups} open={open} onClose={handleClose} />             
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <Grid container justify="space-around"> 
                  <Button variant="outlined" className={classes.button} onClick={() => sumaTotales(props.data)}>
                            Totales
                  </Button>                   
                  <TextField
    id="dateTextFieldInit"
    label="Fecha inicio"
    type="date"
    defaultValue={selectedDate}
    pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
    className={classes.textField}
    InputLabelProps={{
      shrink: true,
    }}
  />

  <TextField
    id="dateTextFieldFin"
    label="Fecha fin"
    type="date"
    defaultValue={selectedDatetwo}
    pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
    className={classes.textField}
    InputLabelProps={{
      shrink: true,
    }}
  />
                  <Button variant="outlined" disabled={btnDisabled} className={classes.button} onClick={() => filter()}>
                    Filtrar
                  </Button>

                  <Button variant="outlined" disabled={btnDisabled} className={classes.button} onClick={() => emptyData()}>
                    Quitar Filtro
                  </Button>
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
            ),
          }}                 
        />
      </div>
    )

}

