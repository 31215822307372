import React, {useContext} from 'react'
import Settings from "../../settings";
import axios from "axios";
import { AppContext } from "../../../contextApi/Context";

import CheckIcon from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";

function Done({

  idcontrol, fechaInicio, TiempoDuracion, fechaReinicio, status, creador, idUser, openTask

}) {

  let FECHAREINICION = fechaReinicio
    const {    
        userData, socket
      } = useContext(AppContext);

      function suma(a, b) {
        var c = parseFloat(a) + parseFloat(b);
        return c;
      }

      async function _done(id, fecha, codigoProducto) {

        localStorage.removeItem(idUser);
    
        const dtimezone = new Date().getTimezoneOffset() * 60000;
        const TodaylocalIsoTime = new Date(Date.now() - dtimezone).toISOString();
    
        var fechaFin = (await new Date().getTime()) - dtimezone;
    
        if (status === "Reiniciado" || status === "Pendiente") {
          var fechaReInicio = await new Date(
            FECHAREINICION
          ).getTime();
          if (fechaReInicio === 0) {
            fechaReInicio = await new Date(fecha).getTime();
          }
          var diff = fechaFin - fechaReInicio;
          var TiempoDuracionTarea = suma(
            TiempoDuracion,
            (diff / 60000).toFixed(1)
          );
          /// devuelve los minutos que se demoro en realizar la tarea
          //var today = await new Date().toISOString();
          var totalTiempoReal = parseFloat(Number(TiempoDuracionTarea)).toFixed(0); /// total = el total del descuento
        } else if (status === "Iniciado") {
          var fechaInicio = await new Date(fecha).getTime();
          var diff = fechaFin - fechaInicio;
          var TiempoDuracionTarea = parseFloat(diff / 60000).toFixed(1);
          /// devuelve los minutos que se demoro en realizar la tarea
          //var today = await new Date().toISOString()
          var totalTiempoReal = parseFloat(Number(TiempoDuracionTarea)).toFixed(0); /// total = el total del descuento
        }
    
        if (TiempoDuracionTarea > 26170000) {
          alert("por favor actualiza el sistema");
          //props.refresh();
         // props.allrefresh();
          
        } else {


          const DoneApi= await axios.put(`${Settings.hostName}/performance/${id}`,{
            id: id,
            fechaFinal:
              TodaylocalIsoTime === undefined
                ? new Date(Date.now() - dtimezone).toISOString()
                : TodaylocalIsoTime,
  
            fechaPausa: "",
            estado: 3,
            duraciontarea: TiempoDuracionTarea,
            tiemporeal: totalTiempoReal,
            idUSer:idUser
          })

          if(DoneApi.status === 200){
            socket.emit('mensaje',{
              name:userData.empleadosNombre,
              mensaje: 'finaliza la tarea',
              data: idUser,
              room: userData.cedulaEncargado === 0?  creador : userData.cedulaEncargado})
  
              socket.emit('grafica',{
                name:userData.empleadosNombre,
                mensaje: 'finaliza la tarea - grafica',
                data: idUser,
                room: userData.cedulaEncargado === 0?  creador : userData.cedulaEncargado})                  
                
            
             if (userData.rolesNombre === "Operario") {
              const open = await openTask('finaliza la tarea');                
              
            }
          }
         
        }
      }

  return (
    <Grid item xs={2}>
            <IconButton
              color="primary"
              aria-label="check"
              disabled={status !== 'Pendiente'? false:true } 
              title="Terminar"
              onClick={() =>
                _done(
                  idcontrol,
                  fechaInicio
                )
              }
            >
              <CheckIcon />
            </IconButton>
          </Grid>
  )
}

export default Done