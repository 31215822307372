import 'date-fns';
import 'moment'
import React, { Component } from 'react';
import Settings from '../settings';
import axios from 'axios'
import dayjs from 'dayjs';
import 'moment-timezone';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
////table

import CircularProgress from '@material-ui/core/CircularProgress';




///filter
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    margin: 'auto',
  },
  table: {
    minWidth: '100%',
  },
  appBar: {
    position: 'relative',
    backgroundColor: '#4C0066',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {   
    justifyContent:'center',    
  },
  root2: {
    marginTop:'5%',
    marginBottom:'5%',
    justifyContent:'center',
    display: 'grid',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  paper: {
    width: 400,
    height: 300,
    overflow: 'auto',
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}




export default function App() {
  const classes = useStyles();
  
  const [values, setValues] = React.useState({
    areaNombre: '',
  });

 
  const [disablebtn, setDisabledbtn] = React.useState('true') 
  const [selectedrow, setSelectedrow] = React.useState(null)  
  const [selectedDate, setSelectedDate] = React.useState();
  const [selectedDatetwo, setSelectedDatetwo] = React.useState();
  const [areas, setAreas] = React.useState({ data: [] })

  const [meses] = React.useState({ data: [{
    value:1,
    text:"1 mes"
  },{
    value:2,
    text:"2 meses"
  },{
    value:3,
    text:"3 meses"
  },{
    value:4,
    text:"4 meses"
  },{
    value:5,
    text:"5 meses"
  },{
    value:6,
    text:"6 meses"
  }] })
 
  const [mes, setMes] = React.useState(1);

  const handleChangeMes = (event) => {
    setMes(event.target.value);

    if(selectedDate !== undefined){
      ChangeFecha(event.target.value)
    }
    
  };

  const [checked,setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value.idsubProceso);
    const newChecked = [...checked];
  
    if (currentIndex === -1) {
      newChecked.push(value.idsubProceso);
    } else {
      newChecked.splice(currentIndex, 1);
    }
  
    setChecked(newChecked);
  };


  const handleCheckedRight = () => {    
    const newLeft = left.filter((value) => !checked.includes(value.idsubProceso));
    const transferredItems = left.filter((value) => checked.includes(value.idsubProceso));
    setLeft(newLeft);
    setRight([...right, ...transferredItems]);
    setChecked([]);

    
  };

  const handleCheckedRightAll = () => {    
    
    setRight([...right,...left]);
    setLeft([])
    setChecked([]);
    
  };


  const handleCheckedLeft = () => {
    const transferredItems = right.filter((value) => checked.includes(value.idsubProceso));
    const newRight = right.filter((value) => !checked.includes(value.idsubProceso));

    setRight(newRight);
    setLeft([...left, ...transferredItems]);
    setChecked([]);
  };

  const handleCheckedLeftAll = () => {  
    setRight([]);
    setLeft([...left,...right]);
    setChecked([]);
    localStorage.removeItem('excluidosDibAI')
  };


 

  const customList = (items) => {   
     return  <Paper className={classes.paper}>
    <List dense component="div" role="list">
      {items?.map((value, index) => {
        const labelId = `transfer-list-item-${value.idsubProceso}-label`;

        return (
          <ListItem key={index} role="listitem" button onClick={handleToggle(value)}>
            <ListItemIcon>
              <Checkbox
                checked={checked.indexOf(value.idsubProceso) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText id={value.idsubProceso} primary={value.subProcesoNombre} />
          </ListItem>
        );
      })}
      <ListItem />
    </List>
  </Paper>     
  }


  const handleClick = name => async event =>{ 
    setSelectedrow(false)
    setDisabledbtn('true')  
    console.log(JSON.stringify(right))

    localStorage.setItem('excluidosDibAI', JSON.stringify(right));


     if (selectedDate !== '' && values.areaNombre !== '' ) {

      const form = new FormData();
      form.append( 'inicial', selectedDate);
      form.append('final', selectedDatetwo);
      form.append('area', values.areaNombre);
      form.append('excluidos', JSON.stringify(right));

        const data = await axios.post(
          `${Settings.hostApiIA}/analized_data_post/`,
          form
        );
        setSelectedrow(data.data)
        setDisabledbtn(null)
      } 
  }

  const ChangeFecha = (mes) =>{
    var date1 = document.querySelector('#dateTextFieldInit')
    

    const fechaInicial = dayjs(date1.value);

    const fechaFinal = fechaInicial.add(mes, 'month');
    const fechaFinalFormateada = fechaFinal.format('YYYY-MM-DD');
    var date2 = document.querySelector('#dateTextFieldFin')
    date2.value = fechaFinalFormateada
    
    setSelectedDate(date1.value); 
    setSelectedDatetwo(fechaFinalFormateada);   
    const fechaActual = dayjs();
    const fechaActualFormateada = fechaActual.format('YYYY-MM-DD');

    if( fechaFinalFormateada > fechaActualFormateada ){ 
      alert('La fecha final es superior al dia de hoy');
      setSelectedDate(null); 
    }

  }

  const handleChange = name => async event =>{
    setSelectedrow(null) 
    if(selectedDate){ setValues({areaNombre:event.target.value})
    setDisabledbtn(null)} 
  else{
    alert('La fecha de Inicio no puede estar vacia')
  }
   
  }




  const Areas = async () => {
    const areas = await axios.get(`${Settings.hostName}/area`);
    setAreas(areas)
    const SubProcesos = await axios.get(`${Settings.hostName}/thread`);

    if(localStorage.getItem("excluidosDibAI")){
      const excluidos = JSON.parse(localStorage.getItem("excluidosDibAI"))
    setRight(excluidos)
    const newLeft = SubProcesos.data.filter(item => !excluidos.some(excludeItem => excludeItem.idsubProceso === item.idsubProceso));    
    setLeft(newLeft);
    }else{
      setLeft(SubProcesos.data) 
    }

    
   
    

    //setLeft(SubProcesos.data)
  }


  
  React.useLayoutEffect(() => {
    Areas()
    


  }, []);

 
  return (
    <div>
       <h2 style={{marginLeft:'5%'}}>Análisis de Tiempos Estandar</h2>
       <div style={{marginTop:'5%'}}>
           
              <Grid container justify="space-around">

              <TextField
                required="true"
                id="meses"
                select
                label="Meses"
                className={classes.textField}
                value={mes}   
                onChange={handleChangeMes}             
                style={{ margin: 8, width: '20%' }}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}

                margin="normal"
                variant="outlined"
              >
                {meses.data.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="dateTextFieldInit"
                label="Fecha inicio"
                type="date"
                onChange={()=>ChangeFecha(mes)}
                defaultValue={selectedDate}
                pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
              disabled="true"
                id="dateTextFieldFin"
                label="Fecha fin"
                type="date"
                defaultValue={selectedDatetwo}
                pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                required="true"
                id="area"
                select
                label="Áreas"
                className={classes.textField}
                value={values.areaNombre}   
                onChange={handleChange('areaNombre')}             
                style={{ margin: 8, width: '20%' }}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}

                margin="normal"
                variant="outlined"
              >
                {areas.data.map(option => (
                  <MenuItem key={option.idarea} value={option.areaNombre}>
                    {option.areaNombre}
                  </MenuItem>
                ))}
              </TextField>
              
              <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      
      <Grid item><h2>Sub procesos a generar</h2>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
        <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRightAll}
            
            aria-label="move selected right"
          >
            &gt;&gt;
          </Button>


          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            
            aria-label="move selected right"
          >
            &gt;
          </Button>
          
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeftAll}
            
            aria-label="move selected left"
          >
            &lt;&lt;
          </Button>
          
        </Grid>
      </Grid>
      
      <Grid item><h2>Sub procesos a descartar</h2>{customList(right)}</Grid>
    </Grid>
              
                 
              <Button
                  onClick={handleClick('areaNombre')}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled={disablebtn}
                >
                  Generar
                </Button>
            </Grid>
            </div>
       {selectedrow && 
       <div className={classes.root2}>     
       <h2>Los tiempos se han generado.</h2>
     </div>}
      {selectedrow === false && 
      <div className={classes.root2}>     
      <CircularProgress color="secondary" />
    </div>}
    </div>
  )

}

