import React from 'react';
import axios from 'axios';import Settings from '../../settings';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Explore';
import Delete from '@material-ui/icons/DeleteOutline';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  table: {
    minWidth: 350,
  },

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

function getStyles(name, productName, theme) {
    return {
      fontWeight:
      productName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }


export default function DialogSelect(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [state, setState] = React.useState({
    open: false,
    prods: '',
  });

  const [dataPro, setDataPro] = React.useState({ data:[]})
 

  async function deleteChild (id){
      await axios.delete(`${Settings.hostName}/productschildrens/${id}`)
      DataProducts()
  }
  

  const handleClickOpen = () => {
    setState({ ...state, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

 

  const DataProducts = async () =>{
      const data = await axios(`${Settings.hostName}/productschildrens/${props.cod}`);
      setDataPro(data)
  }

  React.useEffect(() => {
    DataProducts();
  }, []);

  return (
    <div>
      <DeleteIcon onClick={handleClickOpen}/>
      <Dialog disableBackdropClick disableEscapeKeyDown open={state.open} onClose={handleClose}>
        <DialogTitle>Borrar productos de: {props.cod}</DialogTitle>
        <DialogContent>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">Descripción</TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>{
            dataPro.data.map((element,i)=>{
              return( 
                <TableRow key={i}>
                <TableCell component="th" scope="row">
                <Delete onClick={() => deleteChild(element.idproductsPadreHijo)}/>
                </TableCell>
                <TableCell align="right">{element.productsPadreHijoNombre} -  {element.productsPadreHijoSubProceso}</TableCell>
                </TableRow>
              )
            })
          }
           </TableBody>
        </Table>
         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>          
        </DialogActions>
      </Dialog>
    </div>
  );
}