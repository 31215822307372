/* eslint-disable no-script-url */

import React, {useContext} from 'react';  import Settings from '../settings';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment'
import 'moment-timezone';
import 'moment/locale/es';
import moment from 'moment';
import UnitDeposit from './UnitDeposit'

import { useCountdown } from "use-moment-countdown";


import {AppContext} from '../../contextApi/Context'
import { isNumber } from 'lodash';

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
  orange:{
color: 'orange'
  },
  green:{
    color: 'green'
  }
});



const dateToFormat = new Date();

export default function Deposits(props) {
  const {userData, socket} = useContext(AppContext);

  const classes = useStyles();
  const [taks, setTask] = React.useState({ data: [] })
  const [count, setCount] = React.useState(0)
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  
 async function TotalTiempoReal(items) {  
    let TotalcontrolrendimientoTiempoReal = await items.map(({controlrendimientoTiempoReal}) => controlrendimientoTiempoReal).reduce((sum, i) => sum + i, 0);
    
    return TotalcontrolrendimientoTiempoReal
  }

  async function TotalTiempoEstandar(items) {  
    let TotaltiempoEstandar = await items.map(({tiempoEstandar}) => tiempoEstandar).reduce((sum, i) => sum + i, 0);
    
    return TotaltiempoEstandar
  }
    
  const All = async () => {    /// componentDidMount 
    
     try {
      var myId = userData.Idempleados
    
      if(myId){
        const {data} = await axios.get(`${Settings.hostName}/graph/userid/${myId}`, { cancelToken: source.token })
        
            //setTask(data);
            if(!data){setCount(0)}else{
              let totalTiempoReal = await TotalTiempoReal(data)
              let totalTiempoEstandar = await TotalTiempoEstandar(data)
              
              let CUMPLIMIENTO = (totalTiempoReal/totalTiempoEstandar).toFixed(1) * 100
              //console.log(CUMPLIMIENTO)
             // debugger
             if(!!CUMPLIMIENTO){
              setCount(0)
               return 
             }
             if(!isNaN(CUMPLIMIENTO)){
              setCount(CUMPLIMIENTO)
             }
              
                return true
            }
          
      }  
    } catch (error) {
      return false
    }
    
  }

  React.useEffect(() => {
    All()
    socket.on("grafica", ({mensaje,data,Name}) => {       
      if(data) All();
   })

    return () => {
      source.cancel();
    };
  }, []);

  function Before15Days() {

    return <UnitDeposit data={taks?.data} />


  }

 

  const { start, time, stop } = useCountdown({ h: 8 }, {recurring: true});

  return (
    <>
    
       <h2 color="textSecondary"  className={classes.depositContext} ><Moment interval={10000} format="LL" locale="es">{dateToFormat}</Moment></h2>
       
      {/*  <div onClick={start} onMouseEnter={stop}>{time.format("hh:mm:ss")}</div> componente de restar tiempo*/}
      
        
      <div className={count > 90 ? classes.green: classes.orange}>
      <span>Rendimiento últimos 15 días:</span>
       <h1>{count}%</h1>       
      {/*  <div onClick={start} onMouseEnter={stop}>{time.format("hh:mm:ss")}</div> componente de restar tiempo*/}
      
      </div>
    </>
  );
}