import React, { createContext, useState } from "react";
import Settings from "../components/settings";
import socketIOClient from "socket.io-client";
import axios from 'axios'


export const AppContext = createContext();


const Datafixed = {
    Idempleados: 0,
    empleadosCedula:0,
    empleadosNombre: '', 
    empleadosApellidos: '',      
    rolesNombre:'',    
    empleadosCostoMinuto:'',
    areaNombre:'',
    cedulaEncargado:0
}

const CurrentDate = {
    date:'',
    hourInitial:'',
    hourFinal:''
}


const options = {
    reconnection: true,
    pingInterval: 10,
    pingTimeout: 30,
    rejectUnauthorized: false,
    transports: ['websocket'],
    query: {
      id: 'abcd',
    },
  };
  const socket = socketIOClient(`${Settings.hostSocket}/`, options);



export const AppProvider = ({children}) => {  
const [allProductsVita, setAllProductsVita] = useState({ data:[]});
const [allUsersVita, setAllUsersVita] = useState({ data:[]});
const [allDiscountsVita, setAllDiscountsVita] = useState({ data:[]});
  const [userData, setUserData] = useState(Datafixed);
  const [currentDate, setCurrentDate] = useState(CurrentDate);
  const [mycurrenttask, setMycurrenttask] = useState({ data:[]});
  const [openDisscounts, setOpenDisscounts] = useState(false);
  const [idDisscounts, setIdDisscounts] = useState(0);

  return (<AppContext.Provider value={{
      allProductsVita, setAllProductsVita,
      allUsersVita, setAllUsersVita,
      allDiscountsVita, setAllDiscountsVita,
      userData, setUserData,
      mycurrenttask, setMycurrenttask,
      currentDate,setCurrentDate, 
      socket,
      openDisscounts, setOpenDisscounts,
      idDisscounts, setIdDisscounts    
    }}>{children}</AppContext.Provider>);
};
