import React ,{useEffect, useState}from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Settings from "../settings";
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';

import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';

import Grid from '@material-ui/core/Grid';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import SumItemsDetails from './SumItemsDetails'
import axios from 'axios';

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));




export default  function DetailsDisscount(props) {
   
    const [discounts,setDiscounts] = React.useState({data:[]})  
    const [discountsTotal,setDiscountsTotal] = React.useState(0)  
     const [update,setUpdate]= useState(false)
    const classes = useStyles();     
    
    function subtotal(items) {
        let st = items.map(({ totales }) => totales).reduce((sum, i) => sum + i, 0);        
        return st
      }


    useEffect(() => {

const DataGet = async()=>{

    if(props.expanded){
        const TaskDiscount = await axios(`${Settings.hostName}/performance/detailsDiscounts/${props.idControlTask}`)
        let total= await subtotal(TaskDiscount.data)
        setDiscountsTotal(total)
        setDiscounts(TaskDiscount)
        setUpdate(false)
    }
}
DataGet()

       
    }, [props.expanded])


    useEffect(() => {

        const DataGet = async()=>{
        
            if(update){
                const TaskDiscount = await axios(`${Settings.hostName}/performance/detailsDiscounts/${props.idControlTask}`)
                let total= await subtotal(TaskDiscount.data)
                setDiscountsTotal(total)
                setDiscounts(TaskDiscount)
                setUpdate(false)
            }
        }
        DataGet()
        
               
            }, [update])

    const Details = () => {        
        if (discounts.data?.length > 0) {
            return (
                discounts.data?.map(elemt => (
                    <SumItemsDetails data={elemt} key={elemt.id}  refresh={setUpdate}/>
                ))
            )

        } else {
            return <>No hay descuentos agregados</>
        }
    }

    
    return (
        <Grid item xs={12} lg={12} sm={12}>
            <Collapse in={props.expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Descuentos
          </Typography>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>                                
                                <TableCell align="left">Detalle</TableCell>
                                <TableCell align="left">Unds</TableCell>
                                <TableCell align="left">SubTotal</TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <Details  cargar={setUpdate}/>
                            <TableRow>
                                <TableCell colSpan={2} align="right">Total</TableCell>
                                <TableCell align="left">{discountsTotal} Min.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            </Collapse>
        </Grid>
    );
}