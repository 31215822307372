import React, {useContext} from "react";
import Settings from "../settings";
import { AppContext } from "../../contextApi/Context";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AutoComplete from "./AutoComplete";

import { SnackbarProvider, useSnackbar } from "notistack";
import { BorderAllRounded } from "@material-ui/icons";




const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    padding: "7%",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    width: "100%",
    padding: "15px",
  },
}));

const estado = [
  {
    value: "1",
    label: "Iniciado",
  },
  {
    value: "2",
    label: "Pendiente",
  },
  {
    value: "3",
    label: "Finalizado",
  },
  {
    value: "4",
    label: "Rechazado",
  },
];


const TIMEEXPIRATION = Settings.tiempoduracion;  ///tiempo de expiracion de la tarea que se queda abierta. se reinicia si pasa

function MyAppForm(props) {


  const {    
    userData, socket,allProductsVita
  } = useContext(AppContext);


  const classes = useStyles();
  const dtimezone = new Date().getTimezoneOffset() * 60000;
  let localstorage = localStorage.getItem(props.id);
  const currentDay =
    localstorage === null
      ? new Date(Date.now() - dtimezone).toISOString()
      : JSON.parse(localstorage).fechaCreado;

      const [fechaInicio, setFechaInicio] = React.useState(currentDay);

      const [count, setCount] = React.useState(0);
      //console.log('agregar tarea')

  if(localstorage){    



      var TiempoInicial =  new Date(JSON.parse(localstorage).fechaCreado).getTime();
      var TiempoActual = (new Date().getTime()) - dtimezone;
      var diff = TiempoActual - TiempoInicial;
      var TiempoDuracionTarea = parseFloat(diff / 60000).toFixed(0);

     // 
      if(parseInt(TiempoDuracionTarea) > TIMEEXPIRATION ){
        localStorage.removeItem(props.id);        
        const object = {
          usuario : props.id,
          fechaCreado: (new Date(Date.now() - dtimezone)).toISOString()
        }
       
        localStorage.setItem(props.id,JSON.stringify(object) );      
        setFechaInicio(new Date(Date.now() - dtimezone).toISOString())
      }
      
  }else{ 
    if(props.mensaje === 'mensaje vacio'){
      localStorage.removeItem(props.id);
    }else{
      const object = {
        usuario : props.id,
        fechaCreado: (new Date(Date.now() - dtimezone)).toISOString()
      }
     
      localStorage.setItem(props.id,JSON.stringify(object) );
    }

    


  }


  const [values, setValues] = React.useState({
    empleadoId: 0,
    //fechaInicio: today,// Devuelve 2011-10-05T14:48:00.000Z
    codigoProducto: "",
    estado: 1,
    nombre: "",
    cantidad: 1,
    done: 0,
    pedido: "",
    sucursales: "",
    idprod: "",
  });

  

  const [valuesSP, setValuesSP] = React.useState({
    subProcesoNombre: "",
  });
  const [valuesPRD, setValuesPRD] = React.useState({
    productosNombre: "",
  });

  


  const handleSave = (e) => {  
    e.preventDefault();  
       if (e && count === 0) {
      setCount(count + 1);
      validateAndAdd();     
      e.preventDefault();
    }
    
  };

  const [branch, setBranch] = React.useState({ data: [] });
  const [Prod, setProds] = React.useState({ data:[]});
  const [allProd, setAllProds] = React.useState(false);

  const BorrarData = () => {
    setValues({ ...values, ["codigoProducto"]: '' });
  }

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };
  const handleChangeCantidad = (event) => {
    setValues({ ...values, ["cantidad"]: event.target.value });
  };

  const HandleCode = (e) => {
    handleChangeCodigo(e.target.value)
  }

 

  const handleChangeCodigo = (value) => {
    setValues({ ...values, ["codigoProducto"]: value });
    var data_filter = Prod.data.filter(
      (element) => element.productosCodigo === parseInt(value)
    ); /// filtro el codigo de los productos
    if (data_filter.length === 1) {
      setValues({
        codigoProducto: data_filter[0].productosCodigo,
        nombre: data_filter[0].productosNombre,
        estado: 1,
        //fechaInicio: today,
        cantidad: parseInt(values.cantidad),
        idprod: data_filter[0].idproductos,
        pedido: "",
        sucursales: "",
      });
    } else {
      setValues({
        nombre: "",
        cantidad: parseInt(values.cantidad),
      });
    }
  };

  function SearchProd(name) {
    //console.log(name)
    setValues({ ...values, ["codigoProducto"]: name.productosCodigo });
    var data_filter = Prod.data.filter(
      (element) => element.productosNombre === name.productosNombre && element.subProcesoNombre === name.subProcesoNombre
    ); /// filtro el codigo de los productos
    if (data_filter.length === 1) {
      handleChangeCodigo(data_filter[0].productosCodigo)
      setValues({
        codigoProducto: data_filter[0].productosCodigo,
        nombre: data_filter[0].productosNombre,
        estado: 1,
        //fechaInicio: today,
        cantidad: parseInt(values.cantidad),
        idprod: data_filter[0].idproductos,
        pedido: "",
        sucursales: "",
      });
    } else {
      setValues({
        nombre: "",
        cantidad: parseInt(values.cantidad),
      });
    }
  }
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    enqueueSnackbar("Tarea Creada!", {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
    props.onClose();
  };

  const handleClickVariant = () => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(
      "el codigo no esta registrado como producto, por favor solicitar su ingreso al administrador!",
      {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      }
    );
  };

  const validateAndAdd = () => {
    /// componentDidMount
    if (values.codigoProducto === "") {
      function getProducts() {
       
        return  axios.get(`${Settings.hostName}/products/${userData.areaNombre}`);
      }

      function getBranchs() {
        return axios.get(`${Settings.hostName}/branch`);
      }

      axios.all([ getBranchs()]).then(
        axios.spread(function (listBranch) {
          // Both requests are now complete
          //SetSubproc(listSubProcess);
          setProds(allProductsVita);
          setBranch(listBranch);
          setAllProds(true)
        })
      );
    }

    

    if (values.nombre.length > 0) {
      if (values.nombre === "") {
        handleClickVariant();
        return false;
      } else {
        axios({
          method: "post",
          url: `${Settings.hostName}/performance/add`,
          data: {
            codigo: parseInt(values.idprod),
            idEmpledo: props.id,
            fechaInicio: fechaInicio, // Devuelve 2011-10-05T14:48:00.000Z
            estadoid: values.estado,
            cantidad: values.cantidad,
            factura: values.pedido,
            sucursal: values.sucursales,
            codProducto: parseInt(values.codigoProducto)
          },
        }).then(
          (res) => {
            setValues({
              empleadoId: 0,
              codigoProducto: "",
              estado: 1,
              nombre: "",
              cantidad: 1,
              done: 1,
              idprod: "",
            });
            localStorage.removeItem(props.id);
            //console.log("grabo" + props)
            socket.emit('mensaje',{
              name:userData.empleadosNombre,
              mensaje: 'Crea la tarea',
              data: props,
              room: userData.cedulaEncargado === 0 ?  userData.empleadosCedula : userData.cedulaEncargado})  
                          
           // props.refresh();
            //Broadcast();
            handleClick();
          },
          (err) => {
            console.log(err);
          }
        );
      }
      //Broadcast();
    }
  };

  React.useEffect(() => {
  
    validateAndAdd();
  }, []);

  return (
    
    <>
    { allProd &&
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        <form
          className={classes.container}
          autoComplete="off"
          onSubmit={handleSave}
        >
          <Grid item xs={12} sm={12} lg={12}>

{ (values.codigoProducto !== '' && branch.data) &&

<TextField
id="nombre"
label="Producto"
style={{ margin: 8 }}
placeholder="Digita el Nombre"
fullWidth
disabled
margin="normal"
variant="outlined"
value={values.nombre}
onChange={handleChange('nombre')}
InputLabelProps={{
  shrink: true,
}}
/>

}
{values.codigoProducto === '' &&

<AutoComplete funcSearch={SearchProd} /> 
}

           
           
          </Grid>
          <Grid item xs={6} sm={2} lg={2}>
            <TextField
              required
              id="codigo"
              label="Ítem"
              style={{ margin: 8 }}
              placeholder="Digita el Ítem"
              fullWidth
              onDoubleClick={BorrarData}
              value={values.codigoProducto}
              onChange={HandleCode}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>          
          <Grid item xs={6} sm={2} lg={2}>
            <TextField
              required
              id="cantidad"
              label="Cantidad"
              style={{ margin: 8 }}
              placeholder="Digita la cantidad"
              fullWidth
              type="number"
              margin="normal"
              variant="outlined"
              value={values.cantidad}
              onChange={handleChangeCantidad}
            />
          </Grid>
          <Grid item xs={12} sm={7} lg={7}>
            <TextField
              id="time"
              label="Fecha de Inicio"
              style={{ margin: 8 ,}}
              placeholder=""
              fullWidth
              disabled
              value={fechaInicio}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <TextField
              id="status"
              select
              label=""
              className={classes.textField}
              value={values.estado}
              onChange={handleChange("estado")}
              fullWidth
              disabled
              style={{ margin: 8 }}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="outlined"
            >
              {estado.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <TextField
              id="factura"
              label="No. Pedido"
              style={{ margin: 8 }}
              placeholder="Digita el No. de Pedido"
              fullWidth
              margin="normal"
              variant="outlined"
              value={values.pedido}
              onChange={handleChange("pedido")}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <TextField
              id="branch"
              select
              label="Sucursal"
              className={classes.textField}
              value={values.sucursales}
              onChange={handleChange("sucursales")}
              fullWidth
              style={{ margin: 8 }}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="outlined"
            >
              {branch.data.map((option) => (
                <MenuItem
                  key={option.idsucursales}
                  value={option.sucursalesNombre}
                >
                  {option.sucursalesNombre}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Iniciar
            </Button>
          </Grid>
        </form>
      </Grid>
      }
    </>  
  );
}

export default function AddTask(props) {

  return (
    <SnackbarProvider maxSnack={2}>
      <MyAppForm
        refresh={props.refresh}
        id={props.user}
        onClose={props.onClick}
        mensaje={props.mensaje !== undefined ? props.mensaje:'mensaje vacio'}
      />
    </SnackbarProvider>
  );
}
