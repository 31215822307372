const Setting = {
 //hostName:'https://dibaypi.onrender.com',
// hostSocket :'http://localhost:3006',
hostSocket :'http://198.50.156.11:3006',
//hostSocket :'https://socket-ztuo.onrender.com',
 //hostName:'http://localhost:3311',
hostName:'http://198.50.156.11:3311',
//hostApiIA: 'https://dibai.onrender.com',
//hostApiIA: 'http://localhost:9000',
hostApiIA:'http://198.50.156.11:9000',
    valorDiasGrafico: 15,
    valorDiasDivision: 26,
    tiempoduracion:40
}

export default Setting;