import React, {useContext} from 'react'; import 
Settings from '../settings';
import { AppContext } from "../../contextApi/Context";
import axios from 'axios'
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '7%',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width:'70%'
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 300,
    },
    button: {
      margin: theme.spacing(1),
      width:'100%',
      padding:'15px'
    },
  
  }));
export default function Reasing (props){

  const {    
     socket, userData
  } = useContext(AppContext);
  
    const classes = useStyles();
   
    var valueUser; 
    const [user, setUser] = React.useState({       
        idUser:0,
        cedula:0
    })
    // const [enabled, setEnabled] = React.useState(false)

    const handleChange = event =>{  
       
      let id = event.target.value
      valueUser = props.users.filter(user => user.idempleados === id)
     console.log(valueUser[0])
        setUser({...user,
            ["idUser"]: id,
            ["cedula"]: valueUser[0].empleadosCedula
        })
    }
    



    function _editUser (id){

        axios({
            method: 'put',
            url: `${Settings.hostName}/reasign/${id}`,
            data: {    
              "id": id,
              "userid": user.idUser               
    
            }
          })
            .then(
              (result) => {
                //console.log('aditado usuario')
                //refresh()
                props.refresh();   
              

                  socket.emit('reasignar',{
                    name:userData.empleadosNombre,
                    mensaje: 'reasignar la tarea',
                    data: props,
                    Name: user.cedula,
                    room: userData.cedulaEncargado === 0?  user.cedula : userData.cedulaEncargado,
                    oldroom: props.room,
                  }); 

                   
                  
                  setUser({
                    idUser: 0,
                    cedula:0                
                })
              },
              (error) => {
                console.log(error)
              }
            )
    }
    return(
        <Grid item xs={12}> 
        <TextField
          id="user"
          select
          label="Reasignar"
          className={classes.textField}
          value={user.idUser}          
          onChange={handleChange}                    
          style={{ margin: 8 }}
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
          disabled={props.disabled}
          margin="normal"
          
        >
          {props.users.map(option => (
            <MenuItem key={option.idempleados} value={option.idempleados}>
              {option.empleadosNombre} {option.empleadosApellidos}
            </MenuItem>
          ))}
        </TextField>   
        <IconButton aria-label="Reasignar" disabled={props.disabled} title="Reasignar" onClick={() => _editUser(props.id)} >
          <PersonAddIcon  />
        </IconButton>
        </Grid> 
    )

}

