import React from 'react'; 
import Typography from '@material-ui/core/Typography';

import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/es'


export default function DateTime (props){  
    
    if(props.date){

        const dtimezone = (new Date()).getTimezoneOffset() * 60000;
        
        var fechaInicio = new Date(props.date).getTime() + dtimezone ;
        const timeZone = 'America/Bogota';
        console.log(fechaInicio)
        return(
            <Typography variant="subtitle2" gutterBottom> <Moment format="MMM/D/YY HH:mm"  tz={timeZone} >{fechaInicio}</Moment></Typography>
        )  
    }else{
        return(
            <div></div>
        )  
    }
        

}