/* eslint-disable no-script-url */

import React, { useContext, useLayoutEffect, useEffect } from "react";
import { AppContext } from "../../contextApi/Context";
import Settings from "../settings";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";

import Avatar from "@material-ui/core/Avatar";

import Typography from "@material-ui/core/Typography";
import { green } from "@material-ui/core/colors";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

//iconos
import IconButton from "@material-ui/core/IconButton";
import CalendarIco from "@material-ui/icons/CalendarToday";
import IconTime from "@material-ui/icons/Timer";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import CachedRoundedIcon from "@material-ui/icons/CachedRounded";

import HomeWorkIcon from "@material-ui/icons/Home";
import InboxIcon from "@material-ui/icons/Inbox";
//css
import PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";
import Dialog from "@material-ui/core/Dialog";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import DateTime from "../DateTime";

import { BrowserRouter as Router, Route, Link } from "react-router-dom";

///componentes
import AddTask from "./AddTask";
import ButtonsActions from "./ButtonsActions";
import { isUndefined } from "lodash";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
  },
  cardcontent: {
    padding: "2%",
  },
  grid: {
    marginBottom: 25,
  },
  media: {
    height: 0,
    paddingTop: "12%", // 16:9
  },
  refresh: {
    backgroundColor: "orange",
    marginLeft: "95%",
    maxWidth: "30px",
    maxHeight: "30px",
  },
  progessOn: {
    marginLeft: "48%",
  },
  progessOff: {
    display: "none",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: green[500],
    margin: "0 px",
  },
  ini: {
    backgroundColor: "green",
    width: "20%",
    minHeight: "5rem",
    borderColor: "green",
    borderStyle: "double",
  },
  fin: {
    backgroundColor: "blue",
    width: "20%",
    minHeight: "5rem",
    borderColor: "blue",
    borderStyle: "double",
  },
  pen: {
    backgroundColor: "orange",
    width: "20%",
    minHeight: "5rem",
    borderColor: "orange",
    borderStyle: "double",
  },
  rec: {
    backgroundColor: "red",
    width: "20%",
    minHeight: "5rem",
    borderColor: "red",
    borderStyle: "double",
  },
  reini: {
    backgroundColor: "purple",
    width: "20%",
    minHeight: "5rem",
    borderColor: "purple",
    borderStyle: "double",
  },

  root: {
    backgroundColor: theme.palette.background.paper,
  },
  rootLineal: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function UsersDialogAdd(props) {
  const classes = useStyles();
  const { onClose, open } = props;

  function handleClose() {
    props.f5();
    onClose();
    return;
  }

  return (
    <Dialog fullScreen aria-labelledby="simple-dialog-title" open={open}>
      <AddTask
        refresh={props.refresh}
        user={props.user}
        onClick={handleClose}
        mensaje={props.mensaje}
      />
    </Dialog>
  )
  
}

UsersDialogAdd.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function DialogAddAutomate(props) {
  const classes = useStyles();
  const { onClose, open } = props;

  function handleClose() {
    onClose();
    //props.f5();
    props.countTask(1);
    return;
  }
  return (
    <Dialog
      fullScreen
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <AddTask
        refresh={props.refresh}
        user={props.user}
        onClick={handleClose}
        mensaje={props.mensaje}
      />
    </Dialog>
  );
}
DialogAddAutomate.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function Tasks(props) {
  const classes = useStyles();

  const { userData, socket, allUsersVita, mycurrenttask } =
    useContext(AppContext);

  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [mytasks, setMytasks] = React.useState(mycurrenttask);
  
  const [addbutton, setAddbutton] = React.useState(true);
  const [disable, setDisable] = React.useState(true);
  const [iniciado, setIniciado] = React.useState("");
  const [statuspausado, setStatuspausado] = React.useState(false);
  const [listenBottons, setListenBottons] = React.useState(true);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  function refresTasks() {
    All();
  }

  async function refresh() {
    await All();
    setAddbutton(true);
  }

  function handleClickOpen() {
    setOpen(true);
  }

  const handleCloses = (value) => {
    //socket.emit("new taks", { data: 'nuevo evento' })
    setOpen(false);
  };

  

  function DesactiveAdd() {
    if (mytasks.data.length > 0) {
      var data_filter = mytasks.data.filter(
        (element) => element.EstadoNombre === "Iniciado"
      );
      var data_filter2 = mytasks.data.filter(
        (element) => element.EstadoNombre === "Pendiente"
      );
      var data_filter3 = mytasks.data.filter(
        (element) => element.EstadoNombre === "Reiniciado"
      );

      if (data_filter.length === 1) {
        //oculto boton
        setAddbutton(false);
      }
      if (data_filter2.length > 0) {
        // muestro boton
        setAddbutton(true);
      }
      if (data_filter3.length === 1) {
        // oculto boton
        setAddbutton(false);
      }

      if (mytasks.data.length > 1 && data_filter.length >= 1) {
        //oculto boton
        setAddbutton(false);
      }

      if (mytasks.data.length > 1 && data_filter3.length >= 1) {
        // oculto boton
        setAddbutton(false);
      }
    } else {
      setAddbutton(true);
    }
  }

  const Status = (props) => {
    switch (props.status) {
      case "Iniciado":
        DesactiveAdd();
        return <div aria-label="recipe" className={classes.ini}></div>;

      case "Finalizado":
        return <div aria-label="recipe" className={classes.fin}></div>;

      case "Pendiente":
        DesactiveAdd();
        return <div aria-label="recipe" className={classes.pen}></div>;

      case "Rechazado":
        DesactiveAdd();
        return <div aria-label="recipe" className={classes.rec}></div>;

      case "Reiniciado":
        DesactiveAdd();
        return <div aria-label="recipe" className={classes.reini}></div>;

      default:
        break;
    }
  };

  const IconoTimer = (props) => {
    switch (props.status) {
      case "Iniciado":
        return (
          <CardMedia
            className={classes.media}
            image="../../images/IconoStarted.png"
            title="tareas"
          />
        );

      case "Finalizado":
        return (
          <CardMedia
            className={classes.media}
            image="../../images/IconoDone.png"
            title="tareas"
          />
        );

      case "Pendiente":
        return (
          <CardMedia
            className={classes.media}
            image="../../images/IconoPause.png"
            title="tareas"
          />
        );

      case "Rechazado":
        return (
          <CardMedia
            className={classes.media}
            image="../../images/IconoPause.png"
            title="tareas"
          />
        );

      case "Reiniciado":
        return (
          <CardMedia
            className={classes.media}
            image="../../images/IconoRestarted.png"
            title="tareas"
          />
        );

      default:
        break;
    }
  };

  const [contador, setContador] = React.useState(0);

  const All = async () => {
    /// componentDidMount
    try {
      var myId = userData.Idempleados;
      var area = userData.areaNombre;
      var rol = userData.rolesNombre;
      //console.log(props.myuser[0])
      if (rol !== "Operario") {

        try {
          const TaskData = await axios(
            `${Settings.hostName}/performance/coordinators/${area}`
          );
  
          function agregarboton() {
            if (TaskData.data.length === 0) {
              setAddbutton(true);
              return;
            }
          }
          //setUsers(getUsersArea)
         
          setMytasks(TaskData);
          //setMydiscounts(getDiscounts);
          agregarboton();
          setDisable(false);
          setListenBottons(false);
          
          return true;
        } catch (error) {
          console.log(error)
        }
        
      } else {
        try {
          const TaskData = await axios(
            `${Settings.hostName}/performance/userid/${myId}`
          );

          function agregarboton() {
            if (TaskData.data.length === 0) {
              setAddbutton(true);
              return;
            }
          }
         
          setMytasks(TaskData);
          // setMydiscounts(getDiscounts);
          const EstadoTarea = TaskData.data.filter(
            (task) => task.EstadoNombre !== "Pendiente"
          );
          if (EstadoTarea.length > 0) {
            setIniciado("Iniciado");
          } else {
            setIniciado("Pausado");
          }
          if(TaskData.data.length === 0){
            openAutomateTask("abre tarea");
          }
          
          agregarboton();
          return TaskData;
        } catch (error) {
          return error;
        }
      }
    } catch (error) {
      return error;
    }
  };




  React.useEffect(() => {
    socket.on("mensaje", ({ mensaje, data, Name }) => {

      

      let isDialogDiscount =
        localStorage.getItem("dialogoDisscount") === null ? false : true;

      if (mensaje === "Pauso la tarea" && Name !== userData.empleadosCedula) {
        openAutomateTask("Pauso la tarea");
        localStorage.removeItem(data);
      }

     // if (data && isDialogDiscount === false) All();

      if (mensaje === "Crea la tarea") {
        //All();
        if (isDialogDiscount === false) All();
        if (userData.rolesNombre === "Operario") {
          All();
        }
        localStorage.removeItem(data);
      }

      if (mensaje === "finaliza la tarea") {
        
        if (isDialogDiscount === false) All();
        if (userData.rolesNombre === "Operario") {
          All();
          localStorage.removeItem(data);
          if (mytasks.data.length === 0) {
            openAutomateTask("Pauso la tarea");
          }
         
        }
      }

      if (mensaje === "Elimina la tarea") {
        All();
        if (userData.rolesNombre === "Operario") {
          localStorage.removeItem(data);
          if (mytasks.data.length === 0) {
            openAutomateTask("Pauso la tarea");
          }
        }
      }

      


    });

    socket.on("reinicio", ({ mensaje, data, Name }) => {
      let isDialogDiscount =
        localStorage.getItem("dialogoDisscount") === null ? false : true;
      if (isDialogDiscount === false) All();
      if (userData.rolesNombre === "Operario") { All();}
      if (
        mensaje === "Reinicio la tarea" &&
        Name !== userData.empleadosCedula
      ) {
        localStorage.removeItem(data);
        //console.log('tengo que cerrar el componente de tarea')
        setOpen(false);
      }
    });

    socket.on("reasignar", ({ mensaje, data, Name }) => {
      // All();
      let isDialogDiscount =
        localStorage.getItem("dialogoDisscount") === null ? false : true;
      if (data && isDialogDiscount === false) All();

      if (
        mensaje === "reasignar la tarea" &&
        Name !== userData.empleadosCedula
      ) {
        // console.log('datos recibidos')
        // console.log(data)
        // localStorage.removeItem(data.element.controlRendimientoEmpleadoId);
        //console.log('tengo que cerrar el componente de tarea')
        setOpen(false);
        All();
      }
    });

    

    //Loading();
    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    //debugger
    

    if (mytasks.data.length === 0) {
      All();
    }

    const MyTaskInit = window.localStorage.getItem(userData.Idempleados);
    //debugger    
      // mycurrenttask.data[0].EstadoNombre === "Pendiente"
     
    

    return () => {
      source.cancel();
    };
  }, []);

 
  const IconAddTask = () => {
    if (addbutton) {
      return (
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Fab>
      );
    }
    return false;
  };

  const PauseStatus = (props) => {
    if (props.name === "Pendiente") {
      setStatuspausado(true);
    }
    if (props.name === "Reiniciado") {
      setStatuspausado(false);
    }
    return false;
  };

  const openAutomateTask = (mensaje) => {
    //console.log(userData.Idempleados)
    var MyidUser = userData.Idempleados;
    setOpen(true);
    return (
      <DialogAddAutomate
        open={open}
        onClose={handleCloses}
        refresh={refresh}
        user={MyidUser}
        f5={props.refresh}
        mensaje={mensaje}        
        
      />
    );
  };

  const Titles = (props) => {
    return (
      <>
        <span style={{ fontWeight: "bold", fontSize: "20px" }}>
          {props.codigo}{" "}
        </span>{" "}
        <br />
        {props.productName} <br />
        {props.SubProcesoName} <br />
        <article style={{ wordWrap: "anywhere", fontSize: "11px" }}>
          {props.activity}
        </article>
      </>
    );
  };

  return (
    <>
      {mytasks.data && (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={4}
        >
          
            <Grid item xs={6} sm={4} lg={4}>
              {" "}
              <IconAddTask />
            </Grid>
            <Grid item xs={6} sm={4} lg={4}></Grid>
            <Grid item xs={6} sm={4} lg={4}>
              <Fab
                color="primary"
                aria-label="refresh"
                className={classes.refresh}
                onClick={() => refresTasks()}
              >
                <CachedRoundedIcon />
              </Fab>
            </Grid>
            <UsersDialogAdd
              open={open}
              onClose={handleCloses}
              refresh={refresh}
              user={userData.Idempleados}
              f5={props.refresh}
              mensaje="nueva tarea"
            />

            {mytasks.data.map((element, i) => (
              <Grid item xs={12} sm={6} lg={4} className={classes.grid} key={i}>
                <Card
                  className={classes.card}
                  key={element.idcontrolRendimiento}
                >
                  <CardHeader
                    avatar={<Status status={element.EstadoNombre} />}
                    title={element.idcontrolRendimiento}
                    subheader={
                      <Titles
                        productName={element.productosNombre}
                        SubProcesoName={element.subProcesoNombre}
                        activity={element.actividad}
                        codigo={element.productosCodigo}
                      />
                    }
                  />
                  <IconoTimer status={element.EstadoNombre} />
                  <CardContent className={classes.cardcontent}>
                    <List className={classes.root}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <IconTime size="small" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Tiempo Estandar:" />
                        <Typography variant="subtitle2" gutterBottom>
                          {parseFloat(
                            element.tiempoEstandar *
                              element.controlrendimientoCantidad
                          ).toFixed(1)}{" "}
                          minutos
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <CalendarIco size="small" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Fecha Inicial:" />
                        <DateTime
                          date={element.controlRendimientoFechaInicio}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <CalendarIco size="small" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Fecha Pausa:" />
                        <DateTime date={element.controlRendimientoFechaPausa} />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <InboxIcon size="small" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="No. Pedido: " />
                        <Typography variant="subtitle2" gutterBottom>
                          {element.controlrendimientoNumeroPedido}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <HomeWorkIcon size="small" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Sucursal:" />
                        <Typography variant="subtitle2" gutterBottom>
                          {" " + element.controlrendimientoSucursal}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Creado por:" />
                        {element.empleadosNombre} {element.empleadosApellidos}
                      </ListItem>
                    </List>
                  </CardContent>
                  <PauseStatus name={element.EstadoNombre} />
                  <ButtonsActions
                    listen={listenBottons}
                    element={element}
                    refresh={refresh}
                    allrefresh={props.refresh}
                    status={element.EstadoNombre}
                    disable={disable}
                    pause={statuspausado}
                    timetaks={element.controlrendimientoTiempoDuracion}
                    openNewTask={openAutomateTask}
                    users={allUsersVita}
                    rol={userData.rolesNombre}
                    estadotareas={iniciado}
                    myuser={userData}
                    
                  />
                </Card>
              </Grid>
            ))}

            
        </Grid>
      )}
    </>
  );
}
