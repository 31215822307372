import React, {useContext} from 'react';  import Settings from '../settings';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { AppContext } from "../../contextApi/Context";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';


import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TimerIcon from '@material-ui/icons/AlarmAdd';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';


import Axios from 'axios';
import ItemDisscount from './ItemDisscount'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import { SnackbarProvider, useSnackbar } from 'notistack';
import axios from 'axios';


//// css del componente
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',        
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
}));

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function DisscountDialogs(props) {
    const classes = useStyles();
    const {    
         userData, socket, allDiscountsVita
      } = useContext(AppContext);
    /// cargar todos los valores de descuentos
    const [discounts, setDiscounts] = React.useState( allDiscountsVita )
    const [mydiscounts, setMydiscounts] = React.useState({ data: [] })


/// crear las tareas que se van  aguardar
    const handlerclick = (id, mycount, total) => {
        var _init = mydiscounts
        var filter = _init.data.filter(element => element.id === id);
        if (filter.length > 0) {
            let index;
            for (let i = 0; i < _init.data.length; ++i) {
                if (_init.data[i].id === id) {
                    index = i;
                    break;
                }
            }
            _init.data[index].count = mycount
            _init.data[index].total = total
            setMydiscounts(_init)

        } else {
            _init.data.push({
                'id': id,
                'count': mycount,
                'total': total,
            })
            setMydiscounts(_init)
        }

    }
    
//// mensaje en pantalla
    
/// agregar los descuentos
    const post = async () => {
        
        const id = localStorage.getItem('dialogoDisscountID')
        mydiscounts.data.map(async elmt => 

        {
            const {data, status} = await axios.post(`${Settings.hostName}/discounstPerformance/add`,
            {
                 
                "idControl": id,
                "idDescuento": elmt.id,
                "valor": elmt.count,
                "total": elmt.total
            })
            console.log(status)
            if(status === 200){
                setMydiscounts({ data: [] })
                localStorage.removeItem('dialogoDisscountID')
                localStorage.removeItem('dialogoDisscount')
                setOpen(false)


                socket.emit('mensaje',{
                    name:userData.empleadosNombre,
                    mensaje: 'finaliza la tarea',
                    data: props,
                    room: userData.empleadosCedula})
            }
            

        })

        
    }
///carga los descuentos
    

    //funciones del dialogo  
    const [open, setOpen] = React.useState(false);
    //const [idContainer, setIdContainer] = React.useState(0);

    React.useEffect(() => {
        if(userData.rolesNombre==="Administrador"){
            localStorage.getItem('dialogoDisscount') === null ? setOpen(false) : setOpen(true)
        }
        if(localStorage.getItem('dialogoDisscount')) return
        
    }, [])
    const handleClickOpen = (id) => {
        localStorage.setItem('dialogoDisscount',true );
        localStorage.setItem('dialogoDisscountID',id );
        setOpen(true)
        //setIdDisscounts(id)
       // setOpenDisscounts(true);

    };
    const handleClose = () => {        
        localStorage.removeItem('dialogoDisscountID')
        localStorage.removeItem('dialogoDisscount')
       // setOpenDisscounts(false);
       setOpen(false)
    };


    return (
        <>
        <IconButton disabled={userData.rolesNombre !== 'Operario' ? false: true} title={props.idControl} aria-label="Edit - Add descuentos" onClick={()=>handleClickOpen(props.idControl)} >
                <TimerIcon />
            </IconButton>
       {localStorage.getItem('dialogoDisscount') &&

        <>            
            <Dialog onClose={handleClose}  fullWidth aria-labelledby="customized-dialog-title" onBackdropClick={true}  open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Descuentos : {localStorage.getItem('dialogoDisscountID')}
        </DialogTitle>
                <DialogContent dividers>
                <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>        
                    <List className={classes.root}>
                        {
                            discounts.data.map(elemento => (
                                <Grid item xs={12} lg={12} sm={12} key={elemento.iddescuentos+elemento.descuentosNombre} >
                                <ListItem alignItems="flex-start">
                                    <Grid item xs={6} lg={4} sm={4}>  
                                    <ListItemText
                                        primary={elemento.descuentosNombre}
                                    />
                                    </Grid>
                                    <Grid item xs={6} lg={8} sm={8}>
                                    <ItemDisscount data={elemento.iddescuentos} click={handlerclick} total={elemento.descuentosValor} />
                                    </Grid>
                                </ListItem>
                                <Divider />
                                </Grid>
                            ))
                        }
                    </List>
                    </Paper>
                    </Grid>
                 </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => post(props.idControl)} color="primary">
                        Agregar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
       }
        
       </> 
    );
}



