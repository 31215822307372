import React, {useContext} from 'react';
import Settings from './settings';
import axios from 'axios';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import AccountIcon from '@material-ui/icons/AccountCircleSharp';

//componentes

import ListItems from './ListItems'; /// menu lateral izquiendo
import Chart from './Statistics/Chart'; ///grafico
import Deposits from './Statistics/Deposits'; ///Datos de las tareas realizadas

import AllTaskReport from './Reports/ReportAllTask'
import Tasks from './Control/Tasks';
import ListProducts from './Products/V2/ListProducts'
import ListUsers from './Users/ListUsers'
import ListDiscounts from './Discounts/ListDiscounts'
import ReportsAllUsers from './Reports/ReportsAllUsers'
import ReportCostsUsers from './Reports/ReportCosts'
import ReportDisscountsUsers from './Reports/ReportDisscounts'
import ListThread from './Thread/ListThread'
import ListAreas from './Areas/ListAreas'
import ChangeTimersTask from './ChangeControl/ChangeTasks'
import Branch from './Branch/Branchs'
import ChangeTask from './ChangeControl/ChangeAllTask'
import ReportCostsProducts from './Reports/ReportProductFinal'
import GenerateStandarTimes from './Reports/GenerateStandarTimes'

//import Inventory from './Inventory/Inventory'
////

import useStyles from './styles'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import {AppContext} from '../contextApi/Context'


export default function Dashboard(props) {

  const {userData, socket} = useContext(AppContext);

  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState({ user: [] })
  const[refresh, setRefresh] = React.useState(false)

  React.useEffect(() => {
    const dataUser = props.user[0];
    setUser(dataUser);    
    document.title= userData.empleadosNombre +" "+ userData.empleadosApellidos
    //addUser();    
  }, []);


  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const _logout = () => {
    props.logout();   
  }

  async function Refresh(){
   // Dashboard();
    setRefresh(!refresh)

    return true
  }
  
  ///// NAVIGATION

  const Dashboard = () => {
    return (
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={fixedHeightPaper}>
              <Chart myuser={userData} refresh={refresh} />
            </Paper>
          </Grid>
          {/* Recent Deposits 
          <Grid item xs={12} md={4} lg={3}>
            <Paper className={fixedHeightPaper}>
              <Deposits myuser={userData} refresh={refresh} />
            </Paper>
          </Grid>*/}
          {/* Recent Tasks */}
          <Grid item xs={12} md={12} lg={12}>
            <Paper xs={12} md={12} lg={12} className={classes.paper}>
              <Tasks myuser={userData} refresh={()=>Refresh()}/>
            </Paper>
          </Grid>
        </Grid>
      </Container>

    )

  }
  const Users = () => {
    return (
      <ListUsers />

    )

  }
  const Products = () => {
    return (

      <ListProducts />
    )

  }
  const Discounts = () => {
    return (
      <ListDiscounts />

    )

  }

  const Thread = () => {
    return (
      <ListThread />

    )

  }
  const Standarstimes = () =>{
    return (
      <GenerateStandarTimes />

    )
  }

  const Areas = () => {
    return (
      <ListAreas />

    )

  }
  const ChangeTimers = ()=>{
    return(
      <ChangeTimersTask  user={userData}/>
    )

  }
  const Branchs = ()=>{
    return(
      <Branch />
    )

  }

  const ChangeAllTask = ()=>{
    return(
      <ChangeTask  user={userData}/>
    )

  }
  
 /*  const Inventories = () => {
    return (
      <Inventory />

    )

  } */

  const ReportTasks = () => {
    return (<AllTaskReport />  )
  }

  const ReportTimes = () => {

    return (
      <ReportsAllUsers />
    )
  }
  const ReportCosts = () => {

    return (
      <ReportCostsUsers />
    )
  }
  const ReportDisscounts = () => {

    return (
      <ReportDisscountsUsers />
    )
  }

  const ReportProductTotal = () =>{
    return(
      <ReportCostsProducts />
    )
  }

 

  /// END NAV
  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              {user.empleadosNombre} {user.empleadosApellidos}
              <Badge badgeContent={4} color="secondary" variant="dot">
                <AccountIcon />
              </Badge>
            </Typography>
            
            <IconButton color="inherit" onClick={_logout}>
              <PowerOffIcon />
            </IconButton>
          </Toolbar>
        </AppBar >
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
          <img src="../images/favicon-32x32.png"/>dibAI
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <ListItems myuser={userData} />

        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/products" component={Products} />
          <Route exact path="/Disscounts" component={Discounts} />
          <Route exact path="/ReportTimes" component={ReportTimes} />
          <Route exact path="/ReportCosts" component={ReportCosts} />
          <Route exact path="/ReportDisscounts" component={ReportDisscounts} />
          <Route exact path="/thread" component={Thread} />
          <Route exact path="/areas" component={Areas} />
          <Route exact path="/ChangeTimers" component={ChangeTimers} />
          <Route exact path="/branch" component={Branchs} />
          <Route exact path="/ChangeTasks" component={ChangeAllTask} />
          <Route exact path="/reporttasks" component={ReportTasks} />
          <Route exact path="/reportotalcosto" component={ReportProductTotal} /> 
          <Route exact path="/standarstimes" component={Standarstimes} />
          
          
          {/* <Route exact path="/inventory" component={Inventories} /> */}

          <Footer />
        </main>
      </div>
    </Router>
  );
}



function Footer() {


  return (
    <Typography variant="body2" color="textSecondary" align="center">
      &copy;  - <img src="../images/favicon-32x32.png"/> dibAI Systems
    </Typography>
  );
}
