import React from 'react';  import Settings from '../settings';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import QRCode from 'qrcode.react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';



import { SnackbarProvider, useSnackbar } from 'notistack';


const genero = [
    {
        value: '1',
        label: 'Hombre',
    },
    {
        value: '2',
        label: 'Mujer',
    }

];

const rol = [
    {
        value: '1',
        label: 'Administrador',
    },
    {
        value: '2',
        label: 'Operario',
    },
    {
        value: '3',
        label: 'Supervisor',
      }

];

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '1%'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    button: {
        margin: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop: '15%'
    },
    paperRight: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop: '15%'
    },

}));



function MyAppForm(props) {
    
    const classes = useStyles();
    
    const [values, setValues] = React.useState({
        cedula: 0,
        nombre: '',
        apellidos: '',
        areaid: 0,
        generoid: '',
        rolid: 0,
        costominuto: 0,
        cedulaEncargado:0

    });
    const [areas, setAreas] = React.useState({ data: [] })
    const [oldData, setOldData] = React.useState({
        oldArea:0,
        oldCostoMinuto:0
    })

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });

    };

    const { enqueueSnackbar } = useSnackbar();

    const handleClick = () => {
        enqueueSnackbar('Usuario Editado.', {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
        });
    };


    const handleSave = (e) => {
        editUser()
        e.preventDefault();
    }

    //const [users, setUsers] =  React.useState({ data:[]}) 

    const editUser = async () => {
        /// componentDidMount         
      
          function getArea() {
            return axios.get(`${Settings.hostName}/area`);
          }
      
          axios.all([ getArea()])
            .then(axios.spread(function ( listArea) {
                              // Both requests are now complete
              setAreas(listArea);
            }));         
        
        const id = parseInt(props.id);
        if (values.cedula === 0) { 


            fetch(`${Settings.hostName}/users/${id}`,
                {
                    method: 'GET',
                    mode: 'cors',
                    cache: 'default',

                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
                .then(res => res.json())
                .then(
                    (result) => {                        
                        setValues({
                            cedula: result.empleadosCedula,
                            nombre: result.empleadosNombre,
                            apellidos: result.empleadosApellidos,
                            areaid: result.empleadosAreaId,
                            generoid: result.empleadosGeneroId,
                            rolid: result.empleadosRolId,
                            costominuto: result.empleadosCostoMinuto,
                            cedulaEncargado: result.cedulaEncargado
                        })
                        setOldData({
                            oldArea:result.empleadosAreaId,
                            oldCostoMinuto:result.empleadosCostoMinuto
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                )


        }
        else {
          await  axios({
                method: 'put',
                url: `${Settings.hostName}/${id}`,
                data: {

                    "cedula": values.cedula,
                    "nombre": values.nombre,
                    "apellido": values.apellidos,
                    "area": oldData.oldArea,
                    "genero": values.generoid,
                    "rol": values.rolid,
                    "costominuto": oldData.oldCostoMinuto,

                }
            })
                .then(
                    await axios({
                    method: 'post',
                    url: `${Settings.hostName}/users/add`,
                    data: {
          
                      "cedula": values.cedula,
                      "nombre": values.nombre,
                      "apellido": values.apellidos,
                      "area": values.areaid,
                      "genero": values.generoid,
                      "rol": values.rolid,
                      "costominuto":values.costominuto,
                      "cedulaEncargado": values.cedulaEncargado
          
                    }
                  })
                   .then( (result) => {
                         
                        handleClick();
                        //
                    },
                    (error) => {
                        console.log(error)
                    })
                )

        }

        

    }

    React.useEffect(() => {
        editUser();
    }, []);




    return (
        <React.Fragment>
            <Container fixed>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} sm={6}>
                        <Paper className={classes.paper}>
                            <form className={classes.container} noValidate autoComplete="off" onSubmit={handleSave}>
                                <TextField
                                    id="cedula"
                                    label="Cédula"
                                    style={{ margin: 8 }}
                                    placeholder="Digita la cedula"
                                    fullWidth
                                    value={values.cedula}
                                    onChange={handleChange('cedula')}
                                    type="number"
                                    margin="normal"
                                    variant="outlined"

                                />

                                <TextField
                                    id="nombre"
                                    label="Nombre"
                                    style={{ margin: 8 }}
                                    placeholder="Digita el Nombre"
                                    fullWidth
                                    value={values.nombre}
                                    margin="normal"
                                    variant="outlined"
                                    onChange={handleChange('nombre')}

                                />

                                <TextField
                                    id="apellidos"
                                    label="Apellidos"
                                    style={{ margin: 8 }}
                                    placeholder="Digita los Apellidos"
                                    fullWidth
                                    value={values.apellidos}
                                    margin="normal"
                                    variant="outlined"
                                    onChange={handleChange('apellidos')}

                                />

                                <TextField
                                    id="costominuto"
                                    label="Costo Minuto"
                                    style={{ margin: 8 }}
                                    placeholder="Digita el valor costor por minuto"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    value={values.costominuto}
                                    onChange={handleChange('costominuto')}

                                />
                                <TextField
                                    id="area"
                                    select
                                    label="Seleccionar Área"
                                    className={classes.textField}
                                    value={values.areaid}
                                    onChange={handleChange('areaid')}
                                    fullWidth
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}

                                    margin="normal"
                                    variant="outlined"
                                >
                                    {areas.data.map(option => (
                                        <MenuItem key={option.idarea} value={option.idarea}>
                                            {option.areaNombre}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="genero"
                                    select
                                    label="Seleccionar Genero"
                                    className={classes.textField}
                                    value={values.generoid}
                                    onChange={handleChange('generoid')}
                                    fullWidth
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}

                                    margin="normal"
                                    variant="outlined"
                                >
                                    {genero.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="rol"
                                    select
                                    label="Seleccionar Permiso"
                                    className={classes.textField}
                                    value={values.rolid}
                                    fullWidth
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    onChange={handleChange('rolid')}
                                >
                                    {rol.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="cedulaEncargado"
                                    label="Cédula Encargado"
                                    style={{ margin: 8 }}
                                    placeholder="Digita la cédula del encargado"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    value={values.cedulaEncargado}
                                    onChange={handleChange('cedulaEncargado')}

                                />
                                <Button type="submit" variant="contained" color="primary" className={classes.button}>
                                    Aceptar
      </Button>
                            </form>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={6} sm={6}>
                        <Paper className={classes.paperRight}>
                            <QRCode value={values.cedula.toString()} level='H' />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}



export default function EditUser(props) {
    return (
        <SnackbarProvider maxSnack={2} >
            <MyAppForm users={props.addnewsUser} id={props.id} />
        </SnackbarProvider>
    );
}