/* eslint-disable no-script-url */

import React, {useContext} from 'react';
import axios from 'axios';import Settings from '../../settings';
import { AppContext } from "../../../contextApi/Context";
import MaterialTable from 'material-table'
import MTableToolbar from 'material-table/dist/components/m-table-toolbar'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
//css
import PropTypes from 'prop-types';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import Dialog from '@material-ui/core/Dialog';


import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


import AddProduct from './AddProduct'
import EditProduct from './EditProduct'
import AddChildProduct from './AddChildProduct'
import DeleteChildProduct from './DeleteChildProduct'


const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(1),
  },
  fabAdd: {
    position: 'absolute',
    bottom: theme.spacing(8),
    right: theme.spacing(3),
    zIndex: '99'
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));




function UsersDialogAdd(props) {

  const {    
    socket,
    userData
  } = useContext(AppContext);

  const classes = useStyles();
  const { onClose, open } = props;
  

  function handleClose() {
    socket.emit("new product", { data: 'nuevo evento' })
    onClose();
    return
  }

const Close = () =>{
  onClose();
  return true
}

  if (props.edit === 'true') {
    return (
      <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Link to={`/dashboard`} ><IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton></Link>
            <Typography variant="h6" className={classes.title}>
              Editar Producto
            </Typography>
          </Toolbar>
        </AppBar>
        <EditProduct close={handleClose} addnewsUser={props.addNewUser} id={props.id} />
      </Dialog>
    )
  } else {
    return (
      <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>

        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Nuevo Producto
            </Typography>
          </Toolbar>
        </AppBar>

        <AddProduct addnewsUser={props.addNewUser} />
      </Dialog>
    )

  }


}

UsersDialogAdd.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};



export default function ListProducts() {

  const {    
    socket,
    userData    
  } = useContext(AppContext);

  const [open, setOpen] = React.useState(false);
  const [products, setProducts] = React.useState({ data: [] })

  function addNewUser() {
    All();
  }

  function deleteProduct(id) {
    axios({
      method: 'delete',
      url: `${Settings.hostName}/products/${id}`
    })
      .then(
        (result) => {
          All();
        },
        (error) => {
          console.log('error')
        }
      )


  }



  function handleClickOpen() {
    setOpen(true);
  }

  const handleCloses = value => {
    socket.emit("new product", { data: 'nuevo evento' })
    setOpen(false);

  };

  const All = async () => {
    /// componentDidMount  
   const data = await axios({
      method: 'GET',
      url: `${Settings.hostName}/products/`
    })
    setProducts(data);
     
  }


  React.useEffect(() => {
    All()
    
    socket.on("get product", data => {    
      console.log(data)
      All();   

    })

  }, []);


  const _EditUser = (props) => {
    setOpen(true);
    const { match: { params } } = props;
    //console.log(props)
    return <UsersDialogAdd open={open} onClose={handleCloses} addNewUser={addNewUser} edit='true' id={params.id} />
  }


  const ChangeProducts = async () =>{

    const data = await axios(`${Settings.hostName}/recargarproductos`)
    

    if (data.status === 200 ){
      //alert('productos cargados')
      All()
      return true
    }else{
      alert('error en cargar productos')
    }
  }

  const classes = useStyles();
  return (
    <Router>
      <div>
        <Fab color="primary" aria-label="add" className={classes.fabAdd} onClick={handleClickOpen}>
          <AddIcon />
        </Fab>
        <UsersDialogAdd open={open} onClose={handleCloses} addNewUser={addNewUser} />

        <MaterialTable
          columns={[
            { title: 'Código', field: 'productosCodigo', type: 'numeric', defaultSort: 'asc' },
            { title: 'Producto', field: 'productosNombre' },
            { title: 'Sub Proceso', field: 'subProcesoNombre' },
            { title: 'Tiempo Estandar', field: 'tiempoEstandar', type: 'numeric' },           
            { title: 'Area', field: 'areaNombre' },
            { title: 'Actividad', field: 'actividad' },
            { title: '¿Es padre?', field: 'productosPadre', type: 'boolean'},                      


          ]}
          data={products.data}
          title="Productos"
          options={{
            exportButton: true,
            pageSize: 20,
            pageSizeOptions: [10, 50, 100, 200, 500, 1000, 2000],
            doubleHorizontalScroll: true

          }}
          localization={{
            body: {
              emptyDataSourceMessage: 'No hay Datos'
            },
            toolbar: {
              searchTooltip: 'Busqueda',
              searchPlaceholder: 'Buscar...'
            },
            pagination: {
              labelRowsSelect: 'Filas',
              labelDisplayedRows: ' {from}-{to} de {count}',
              firstTooltip: 'Primero',
              previousTooltip: 'Anterior',
              nextTooltip: 'Siguiente',
              lastTooltip: 'Último'
            },
            grouping: {
              placeholder: 'Arrastra aquí la columna que se desea agrupar'
            },

          }}

          detailPanel={[
            {
              tooltip: 'Editar',
              render: rowData => {
                if(rowData.productosPadre !== 0){
                  
                  return ( 
                    <div>
                      <Link to={`/product/${rowData.idproductos}`} >
                        <Fab color="secondary" aria-label="edit" className={classes.fab} >
                          <EditIcon />
                        </Fab>
                      </Link>
                      <Fab aria-label="delete" className={classes.fab} onClick={() => deleteProduct(rowData.idproductos)}>
                        <DeleteIcon />
                      </Fab>
                      <Fab aria-label="add" className={classes.fab} >
                      <AddChildProduct cod={rowData.productosCodigo} idAreaProducto={rowData.areaId} refresh={All}/> 
                      </Fab>
                      <Fab aria-label="add" className={classes.fab} >
                      <DeleteChildProduct cod={rowData.productosCodigo}/> 
                      </Fab>
                      
                    </div>
                  )
                }else{
                  return ( 
                    <div>
                      <Link to={`/product/${rowData.idproductos}`} >
                        <Fab color="secondary" aria-label="edit" className={classes.fab} >
                          <EditIcon />
                        </Fab>
                      </Link>
                      <Fab aria-label="delete" className={classes.fab} onClick={() => deleteProduct(rowData.idproductos)}>
                        <DeleteIcon />
                      </Fab>
                    </div>
                  )
                }
                
              },
            }
          ]}
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <Grid container justify="space-around">
                <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={ChangeProducts}
      >
        Recargar Productos
      </Button>
              </Grid>
              </div>
            ),
          }}
        />

      </div>
      <Route path="/product/:id" component={_EditUser} />
    </Router>
  );
}