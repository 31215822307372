import React from 'react';  import Settings from '../settings';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Delete from '@material-ui/icons/Clear';
import axios from 'axios';


export default function SumItemsDetails(props) { 

    async function _delete (idControl, idelement) {
        
      const Delete = await axios.delete(`${Settings.hostName}/performance/detailsDiscounts/${idControl}/${idelement}`)

          if(Delete.status === 200) props.refresh(true)
    }

     return ( 
        <TableRow key={props.data.id}>        
        <TableCell align="left">
            <Delete onClick={() => _delete(props.data.controlrendimientoID, props.data.id)} />
            {props.data.descuentosNombre}</TableCell>
        <TableCell align="left">{props.data.cantidad}</TableCell>
        <TableCell align="left">{props.data.totales}</TableCell>        
        </TableRow>
    );
}