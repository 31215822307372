import React, {useContext} from 'react';
import axios from 'axios';import Settings from '../../settings';

import { AppContext } from "../../../contextApi/Context";

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import QRCode from 'qrcode.react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/Add';
///dialog imports
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';


import { SnackbarProvider, useSnackbar } from 'notistack';



///componentes




const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '1%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    marginTop: '15%'
  },
  paperRight: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: '15%'
  },
  fab: {
    margin: theme.spacing(1),
  },
  appBar: {
    position: 'relative',
  },
}));


function DialogAdd(props) {

  const classes = useStyles();
  const { onClose, open } = props;

  function handleClose() {
    onClose();
    return
  }



  if (props.view === 'view') {
    return (
      <Dialog fullWidth onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Materiales consumibles del producto : {props.descripcion}
            </Typography>
          </Toolbar>
        </AppBar>
       
      </Dialog>
    )
  } else {
    return (
      <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>

        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Materiales para: {props.descripcion}
            </Typography>
          </Toolbar>
        </AppBar>
      
        
      </Dialog>
    )

  }


}

DialogAdd.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};




function MyAppForm(props) {

  const {    
     socket
  } = useContext(AppContext);


  const [open, setOpen] = React.useState(false);
  const [nameview, setNameview] = React.useState()
  const classes = useStyles();
  const [values, setValues] = React.useState({
    codigo: '',
    nombre: '',
    idSubproceso: '',
    tiempo: 0,
    idInventoryType: '',
    padre:false,
    actividad:'',
    idArea:0,

  });

  const [subproc, SetSubproc] = React.useState({ data: [] })
  const [Prod, setProds] = React.useState({ data: [] })
  const [inventorytype, setInventorytype] = React.useState({ data: [] })

  const [area, setArea] = React.useState({ data: [] })

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });

  };
  
  const handleChangeCK = name => event => {
    setValues({ ...values, [name]: event.target.checked });
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    enqueueSnackbar('Producto agregado.', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  };
const handleClickDialog = () =>{
  if (Prod.data.length !== 0) {
    var data_filter = Prod.data.filter(element => element.productosCodigo === parseInt(values.codigo)) //
    if (data_filter.length > 0) {
      handleClickVariant();
      return false

    }if(values.codigo === ''){
      handleClickVariant();
      return false
    }else{
      setOpen(true);
      setNameview('add')
    }
  }else{
    handleClickVariant();
    return false
  }
  
}

const handleClickDialogView = () =>{
  if (Prod.data.length !== 0) {
    var data_filter = Prod.data.filter(element => element.productosCodigo === parseInt(values.codigo)) //
    if (data_filter.length > 0) {
      handleClickVariant();
      return false

    }else if(values.codigo === ''){
      handleClickVariant();
      return false
    }else{
      setOpen(true);
      setNameview('view')
    }
  }else{
    handleClickVariant();
    return false
  }
}

const handleCloses = value => {
  setOpen(false);

};
  const handleClickVariant = () => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar('el producto ya esta registrada!', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  };


  const handleSave = (e) => {
    validateAndAdd();
    e.preventDefault();
  }


  const validateAndAdd = () => {
    /// componentDidMount           

    const codigo = { values };
    function getProducts() {
      return axios.get(`${Settings.hostName}/products`);
    }

    function getSubProceso() {
      return axios.get(`${Settings.hostName}/subprocess`);
    }

    function getInventoryType() {
      return axios.get(`${Settings.hostName}/inventorytype`);
    }
    function getArea() {
      return axios.get(`${Settings.hostName}/area`);
    }

    axios.all([getProducts(), getSubProceso(), getInventoryType(), getArea()])
      .then(axios.spread(function (listProcess, listSubProcess, listInventoryType, getArea) {
        // Both requests are now complete
        SetSubproc(listSubProcess);
        setProds(listProcess);
        setInventorytype(listInventoryType);
        setArea(getArea)

      }))
      .catch(function (error){console.log(error)});


    if (Prod.data.length !== 0) {
      var data_filter = Prod.data.filter(element => element.productosCodigo === parseInt(codigo.values.codigo)) /// filtro el password del usuario en todos los usuarios.
      // validar si el usuario ya existe en la base de datos
      if (data_filter.length > 0) {
        handleClickVariant();
        return false

      }
      else {

        axios({
          method: 'post',
          url: `${Settings.hostName}/products/add`,
          data: {

            "codigo": parseInt(values.codigo),
            "nombre": values.nombre,
            "idsubproceso": parseInt(values.idSubproceso),
            "tiempoestandar": values.tiempo,
            "tipoInventarioID": parseInt(values.idInventoryType),            
            "actividad": values.actividad,
            "areaID": values.idArea,
            "padre": values.padre,


          }
        })
          .then(
            (res) => {
              setValues({
                codigo: '',
                nombre: '',
                idSubproceso: '',
                tiempo: 0,
                idInventoryType: '',
                padre:false,
                actividad:'',
                idArea:0,
              });

              handleClick();
              props.users();
              Broadcast();
            }, (err) => {
              console.log(err)
            }

          )

      }
    }



  }

  React.useEffect(() => {
    validateAndAdd();
  }, []);


  const Broadcast = () => {
    socket.emit("new product", { data: 'nuevo evento' })
    //GetListMyDisscounts()
  }  
 
  return (<React.Fragment>

    <Container fixed>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6} sm={6}>
          <Paper className={classes.paper}>
            <form className={classes.container} autoComplete="off" onSubmit={handleSave}>
              <TextField
                required
                id="codigo"
                label="Ítem"
                style={{ margin: 8 }}
                placeholder="Digita el codigo de ítem"
                fullWidth
                value={values.codigo}
                onChange={handleChange('codigo')}
                type="number"
                margin="normal"

              />

              <TextField
                required
                id="nombre"
                label="Descripción de ítem"
                style={{ margin: 8 }}
                placeholder="Digita descripción"
                fullWidth
                margin="normal"

                value={values.nombre}
                onChange={handleChange('nombre')}

              />

<TextField
                
                id="actividad"
                label="Descripción de actividad"
                style={{ margin: 8 }}
                placeholder="Digita actividad"
                fullWidth
                margin="normal"
                multiline
                rows={3}
                value={values.actividad}
                onChange={handleChange('actividad')}

              />
              <TextField
                required
                id="idArea"
                select
                label="Seleccionar Área"
                className={classes.textField}
                value={values.idArea}
                onChange={handleChange('idArea')}
                fullWidth
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}

                margin="normal"

              >
                {area.data?.map(option => (
                  <MenuItem key={option.idarea} value={option.idarea}>
                    {option.areaNombre}
                  </MenuItem>
                ))}
              </TextField>


              <TextField
                required
                id="idSubproceso"
                select
                label="Seleccionar Subproceso"
                className={classes.textField}
                value={values.idSubproceso}
                onChange={handleChange('idSubproceso')}
                fullWidth
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}

                margin="normal"

              >
                {subproc.data.map(option => (
                  <MenuItem key={option.idsubProceso} value={option.idsubProceso}>
                    {option.subProcesoNombre}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required
                id="idInventoryType"
                select
                label="Seleccionar tipo de inventario"
                className={classes.textField}
                value={values.idInventoryType}
                onChange={handleChange('idInventoryType')}
                fullWidth
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}

                margin="normal"

              >
                {inventorytype.data.map(option => (
                  <MenuItem key={option.idtipoinventario} value={option.idtipoinventario}>
                    {option.tipoinventarioNombre}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                id="tiempo"
                label="Tiempo estándar"
                style={{ margin: 8 }}
                placeholder="Digita el tiempo"
                fullWidth
                value={values.tiempo}
                onChange={handleChange('tiempo')}
                type="number"
                margin="normal"


              />
              <FormControlLabel
                  control={
                    <Switch  checked={values.padre} onChange={handleChangeCK('padre')} value={values.padre} />
                  }
                  label="¿Es Producto Padre?"
                />                

              <Button type="submit" variant="contained" color="primary" className={classes.button}>
                Aceptar
            </Button>

            </form>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6} sm={6}>
          <Paper className={classes.paperRight}>
            <QRCode value={values.codigo} level={'L'} size={200} />
          </Paper>
          <Grid item xs={12} lg={6} sm={6}>         

          </Grid>
          
                
        </Grid>
        <DialogAdd open={open} onClose={handleCloses} codigo={values.codigo} descripcion={values.nombre} view={nameview} />
      </Grid>
    </Container>
  </React.Fragment >
  );
}






export default function AddProduct(props) {

  return (


    <SnackbarProvider maxSnack={2} >

      <MyAppForm users={props.addnewsUser} />

    </SnackbarProvider>

  );
}