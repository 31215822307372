/* eslint-disable no-script-url */

import React from 'react';  import Settings from '../settings';
import axios from 'axios'

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//css
import PropTypes from 'prop-types';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import Dialog from '@material-ui/core/Dialog';


import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


import AddUser from './AddUser'
import EditUser from './EditUser'
const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));


function UsersDialogAdd(props) {

  const classes = useStyles();
  const { onClose, open } = props;

  function handleClose() {
    props.addNewUser();
    onClose();
    return
  }



  if (props.edit === 'true') {
    return (
      <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Link to={`/dashboard`} ><IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton></Link>
            <Typography variant="h6" className={classes.title}>
              Editar usuario
            </Typography>
          </Toolbar>
        </AppBar>
        <EditUser addnewsUser={props.addNewUser} id={props.id} />
      </Dialog>
    )
  } else {
    return (
      <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>

        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Agregar nuevo usuario
            </Typography>
          </Toolbar>
        </AppBar>

        <AddUser addnewsUser={props.addNewUser} />
      </Dialog>
    )

  }


}

UsersDialogAdd.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};



export default function ListUsers() {
  const [open, setOpen] = React.useState(false);
  const [users, setUsers] = React.useState({ data: [] })

  function addNewUser() {
    Alluser();
  }

  function deleteUser(id) {
    
    axios({
      method: 'delete',
      url: `${Settings.hostName}/${id}`
    })
      .then(
        (result) => {
          Alluser();
        },
        (error) => {
          console.log('error')
        }
      )


  }



  function handleClickOpen() {
    setOpen(true);
  }

  const handleCloses = value => {
    setOpen(false);

  };

  const Alluser = async () => {
    /// componentDidMount          
    const ListsUsers = await axios(`${Settings.hostName}/users`);
    setUsers(ListsUsers);
  }


  React.useEffect(() => {
    Alluser()
  }, []);


  const _EditUser = (props) => {
    setOpen(true);
    const { match: { params } } = props;
    return <UsersDialogAdd open={open} onClose={handleCloses} addNewUser={addNewUser} edit='true' id={params.id} />
  }

  const classes = useStyles();
  return (
    <Router>
      <div>
        <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleClickOpen}>
          <AddIcon />
        </Fab>
        <UsersDialogAdd open={open} onClose={handleCloses} addNewUser={addNewUser} />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Cédula</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Apellidos</TableCell>
              <TableCell>Área</TableCell>
              <TableCell>Costo Minuto</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {
              users.data.map(user => (
                <TableRow key={user.Idempleados}>
                  <TableCell>{user.empleadosCedula}</TableCell>
                  <TableCell>{user.empleadosNombre}</TableCell>
                  <TableCell>{user.empleadosApellidos}</TableCell>
                  <TableCell>{user.areaNombre}</TableCell>
                  <TableCell>{user.empleadosCostoMinuto}</TableCell>
                  <TableCell>
                    <Link to={`/edit/${user.Idempleados}`} >
                      <Fab color="secondary" aria-label="edit" className={classes.fab}>
                        <EditIcon />
                      </Fab>
                    </Link>
                    <Fab aria-label="delete" className={classes.fab} onClick={() => deleteUser(user.Idempleados)}>
                      <DeleteIcon />
                    </Fab>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>

      </div>
      <Route path="/edit/:id" component={_EditUser} />
    </Router>
  );
}